.dashboardRolesContainer {
    padding-top: 1rem;

    s .rolesContainer {

        // border: 1px solid red;
        .btnSpace {
            padding: .2rem 2rem 0 2rem;

        }
    }
}

.roleheading {
    margin-left: 1%;
}