@import "rsuite/dist/rsuite.css";

.PersonalInfoMain {
    background: #FFFFFF;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 2rem;

    .head {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .iconpersonalInfo {
            color: var(--dark);
        }

        .headingInfo {
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;

            @media screen and (max-width:800px) {
                font-size: 13px !important;
            }
        }

    }

    .inputMain {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        gap: 1rem;
        width: 100%;

        .inputFields {
            gap: 2rem;
            display: flex;
            width: 100%;

            @media screen and (max-width:800px) {
                flex-direction: column;
            }

            .field {
                display: flex;
                width: 100%;
                flex-direction: column;

                .rs-input-group {
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;
                }

                .rs-input-group-focus {
                    border: none !important;
                    outline: none !important;
                    box-shadow: none !important;

                }

                .rs-picker-default .rs-picker-toggle.rs-btn {
                    border: transparent;
                    box-shadow: none;
                    align-items: center;
                }

                .rs-picker-toggle-wrapper {
                    display: flex;
                    width: 100%;
                }

                .antMultipleSelector {
                    max-height: 40px;
                    max-width: 100% !important;
                    overflow-y: scroll;
                }

                .selector {
                    border: transparent;
                    box-shadow: none;
                    display: flex;
                    width: 100%;

                    @media screen and (max-width:800px) {
                        font-size: 14px;
                    }

                    @media screen and (max-width:500px) {
                        font-size: 12px;
                    }
                }


                .selecticon {
                    padding-left: 0.3rem;
                }

                .dateicon {
                    padding-left: 0.3rem;
                }


                .inputselect {
                    width: 100%;
                    min-width: 10rem;
                    height: 71px;
                    padding: 12px !important;
                    background: white;
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 9px;
                    gap: 0.3rem;
                    display: flex;
                    align-items: center;

                    .dateicon {
                        @media screen and (max-width:500px) {
                            display: none;
                        }
                    }

                    @media screen and (max-width:800px) {
                        height: 40px !important;
                        padding: 6px !important;
                        font-size: 14px;
                    }

                    @media screen and (max-width:500px) {
                        font-size: 12px;
                    }

                    .iconInfo {
                        @media screen and (max-width:500px) {
                            display: none;
                        }
                    }

                    .ant-select-dropdown .ant-select-item-option-content {
                        @media screen and (max-width:800px) {
                            font-size: 14px;
                        }

                        @media screen and (max-width:500px) {
                            font-size: 12px;
                        }
                    }

                    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
                        @media screen and (max-width:800px) {
                            font-size: 14px;
                        }

                        @media screen and (max-width:500px) {
                            font-size: 12px;
                        }
                    }

                    .ant-select-multiple .ant-select-selection-item-content {
                        @media screen and (max-width:800px) {
                            font-size: 14px;
                        }

                        @media screen and (max-width:500px) {
                            font-size: 12px;
                        }

                    }
                }

                .rs-picker-has-value .rs-btn .rs-picker-toggle-value,
                .rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value {
                    color: black;
                }

                .rs-picker-toggle-textbox {
                    padding-left: 0;
                }

                .ant-select-selector {
                    border: none;
                    display: flex;
                    justify-content: center;
                    font-size: 1rem;
                    display: flex;
                    align-items: center;
                    height: -webkit-fill-available;
                    scroll-behavior: smooth;
                    padding: 0 0.3rem;
                }

                .input {
                    width: 100%;
                    min-width: 10rem;
                    height: 71px;
                    padding: 12px !important;
                    background: white;
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 9px;
                    gap: 0.3rem;

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        -webkit-box-shadow: 0 0 0 30px white inset !important;
                    }

                    @media screen and (max-width:800px) {
                        height: 40px !important;
                        padding: 6px !important;
                        font-size: 14px;
                    }

                    @media screen and (max-width:500px) {
                        font-size: 12px;
                    }

                    .iconInfo {
                        @media screen and (max-width:500px) {
                            display: none;
                        }
                    }

                    .iconInfo {
                        color: var(--dark);
                    }



                    .ant-select-selector[placeholder] {
                        justify-content: center;
                    }
                }
            }
        }

        .yellowGraBtn {
            width: 7rem;
            display: flex;
            align-self: flex-end;
        }
    }
}