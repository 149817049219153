@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

/* ************ Reset Css  ************** */

* {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  scroll-behavior: auto;
  font-family: "Poppins", sans-serif;
  user-select: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

body {
  background-color: var(--pageBackgroundColor);
  height: 100vh;
  width: 100vw;
}

/* ************ Css Variables ************** */

:root {
  --light: #fff;
  --dark: #42464e;
  --themeColor: #fcd117;
  --themeColorGreen: #00a859;
  --themeColorGreenGradient: linear-gradient(96.93deg,
      #02c464 0%,
      #84f96a 100%);
  --themeBackGreenGradient: linear-gradient(274.64deg,
      #02a800 5.4%,
      #1ad56c 94.36%);
  --themeBackRedGradient: linear-gradient(275.12deg,
      #b40901 5.38%,
      #fb4b4d 94%);
  --themeBackYellowGradient: linear-gradient(274.64deg,
      #fdbd21 5.4%,
      #ffd901 94.36%);
  --themeBackGreenBoxShadow: 0px 2px 4px rgba(25, 211, 105, 0.8);
  --themeBackRedBoxShadow: 0px 2px 4px rgba(239, 64, 64, 0.8);
  --themeBackYellowBoxShadow: 0px 2px 4px rgba(255, 214, 3, 0.5);
  --themeColorYellowLight: #fff5c9;
  --themeColorDark: #282c35;
  --themeColorDarkIcon: #a9a9a9;
  // --pageBackgroundColor: #f3f9fd;
  --pageBackgroundColor: linear-gradient(180deg, #F3F9FD 0%, #F2F8FD 100%);
  ;
  --radius: 0.375rem;
  --border: rgba(0, 0, 0, 0.3);
  // --danger: #FF1E1E;
  --danger: #d73a3a;
  --dangerGradient: linear-gradient(276.38deg, #fb5f53 7.35%, #be2f5d 71.62%);

  --primaryColor: var(--themeColor);
  --primaryTextColor: var(--dark);

  --secondaryColor: var(--themeColorDark);
  --secondaryIconColor: var(--themeColorDarkIcon);
  --secondaryTextColor: var(--light);
}

/* ************ SCSS Variables ************** */
%GreenButton {
  max-height: 40px;
  height: 40px;
  width: 100%;
  background: #00a859;
  padding: 20px 35px;
  color: #fff;
  border-radius: var(--radius);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

%YellowButton {
  max-height: 40px;
  height: 40px;
  width: 100%;
  background: var(--themeColor);
  padding: 20px 35px;
  color: var(--light);
  border-radius: var(--radius);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

%YellowButton2 {
  max-height: 40px;
  height: 40px;
  background: var(--themeColor);
  padding: 20px 35px;
  color: var(--light);
  border-radius: var(--radius);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

%DashboardButton {
  max-height: 30px;
  height: 30px;
  min-height: 30px;
  width: 100%;
  padding: 20px 35px;
  color: var(--themeColor);
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: linear-gradient(101.19deg, #143745 2.03%, #0a1c29 100%);
  color: var(--themeColor);
  box-shadow: 0px 0px 15px 2px rgba(17, 49, 63, 0.3);
  transition: ease-in-out 0.2s;

  &:hover {
    color: var(--themeColor) !important;
    border: none;

    scale: 1.01;
  }
}

/* ************ Custom  ScrollBar ************** */

.errorMessage {
  color: red;
}

::-webkit-scrollbar {
  // display: none;
  width: 5px;
}

::-webkit-scrollbar-track {
  border-radius: 10px !important;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  margin-top: 1rem;
  border-radius: 10px !important;
  background: #d1d1d1;
}

/* ************ Input placeholder ************** */
::placeholder {
  color: var(--border) !important;
  /* Firefox */
}

:-ms-input-placeholder {
  color: var(--border) !important;
  /* Internet Explorer 10-11 */
}

::-ms-input-placeholder {
  color: var(--border) !important;
  /* Microsoft Edge */
}

/* ************ Custom Classes ************** */

.container {
  max-width: 1400px;
  margin: 0 auto;

  @media screen and (max-width: "1450px") {
    max-width: 1050px;
  }

  @media screen and (max-width: "1100px") {
    margin: 0 20px;
  }
}
table {
  table-layout: fixed !important;
  width: 100% !important;
}
.lableName {
  padding: 0.5rem 3px;
  font-size: 14px;
  font-weight: 400;
  color: var(--dark);
}

.cursor {
  cursor: pointer;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.3);
}

input {
  accent-color: var(--themeColorGreen);
}

.greenButton {
  @extend %GreenButton;
}

// Flex Properties
.flexLineSpace {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.flexLine {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.flexColumn-600 {
  @media screen and (max-width:600px) {
    flex-direction: column;
  }
}

.inputBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  input {
    width: 100%;
  }
}

// ProgressBox
.progreesBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .progress {
    color: var(--themeColor) !important;
    height: 30px !important;
    width: 30px !important;
  }
}

// page headings's css
.heading {
  color: var(--primaryTextColor);
  font-size: 1.7rem;
  font-weight: 600;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
}

.subHeading {
  color: var(--primaryTextColor);
  font-size: 1.3rem;
  font-weight: 600;
}

// Buttons
.greenBtn {
  max-height: 40px;
  height: 40px;
  min-width: 20px;
  width: 100%;
  background: var(--themeColorGreen);
  padding: 20px 35px;
  color: #fff;
  border-radius: var(--radius);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
}

.greenGradientBtn {
  max-height: 40px;
  height: 40px;
  min-width: 20px;
  width: 100%;
  background: var(--themeBackGreenGradient);
  padding: 20px 35px;
  color: #fff;
  border-radius: var(--radius);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;

  &:hover {
    background: var(--themeBackGreenGradient) !important;
  }
}

.redGradientBtn {
  max-height: 40px;
  height: 40px;
  min-width: 20px;
  width: 100%;
  background: var(--themeBackRedGradient);
  padding: 20px 35px;
  color: #fff;
  border-radius: var(--radius);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  gap: 1rem;

  &:hover {
    background: var(--themeBackRedGradient) !important;
  }
}

.yellowGradientBtn {
  max-height: 40px;
  height: 40px;
  min-width: 20px;
  width: 100%;
  background: var(--themeBackYellowGradient);
  padding: 20px 35px;
  color: #fff;
  border-radius: var(--radius);
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none !important;
  gap: 1rem;

  &:hover {
    background: var(--themeBackYellowGradient) !important;
  }

  .CommonBtnBlog {
    height: 16px;
    width: 15px;
  }
}

.yellowBtn {
  @extend %YellowButton;
}

.yellowGraBtn {
  @extend %YellowButton;
  background: linear-gradient(94.04deg, #ffc01e 22.18%, #ffdb7e 100%);
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 76px;
}

.yellowGraBtn2 {
  @extend %YellowButton2;
  background: linear-gradient(94.04deg, #ffc01e 22.18%, #ffdb7e 100%);
  box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 76px;
}

.dashboardBtn {
  @extend %DashboardButton;
}

.upper {
  text-transform: uppercase;
}

.dashboardHeading {
  height: 70px;
  position: sticky;
  background-color: var(--pageBackgroundColor);
  z-index: 5;
  display: flex;
  align-items: center;
  top: -25px;
}

/* ************ ANT-Design ************** */
// ANT-D Input
.ant-input {
  border-color: var(--border);
}

.ant-input-status-error {
  border-color: #ff4d4f !important;

  &:hover {
    border-color: #ff4d4f !important;
  }
}

.ant-input-affix-wrapper {
  padding: 9px 20px !important;
  border-radius: var(--radius);
  border: 1px solid var(--border);

  &:hover {
    border-color: var(--themeColor) !important;
  }
}

.ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input:focus {
  box-shadow: none;
}

// ANT-D Selector
.ant-select-single {
  font-size: 14px !important;
  width: 100% !important;
}

.ant-select-selector {
  width: 100%;
  height: 42px;
  padding: 5px 20px;
  box-shadow: none;
  border-radius: var(--radius);
  border: 1px solid var(--border);

  &:hover {
    border-color: var(--themeColor) !important;
  }

  &:focus {
    border-color: var(--themeColor) !important;
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: var(--lightYellow) !important;
}

// ANT-D Switch
.ant-switch.ant-switch-checked {
  background-color: var(--themeColor) !important;
}

// ANT-D TAGS :
.userTags {
  // width: 85px;
  text-align: center;
  font-size: 0.65rem;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10rem;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  font-weight: 600;
}


.ant-select-multiple {
  overflow-y: scroll;
  max-height: 28px !important;
}


@media screen and (max-width: 700px) {
  .ant-select-multiple {
    overflow: hidden;
    scrollbar-width: none;
    max-height: 28px !important;
  }

  .ant-select-selection-overflow {
    overflow: auto;
    -ms-overflow-style: none;
    flex-wrap: nowrap !important;
    max-width: 90% !important;
    margin-right: 2rem;
    scrollbar-width: 5px;
  }
}


// ANT-D Date picker
.ant-picker {
  border: 1px solid var(--border);
}

// ANT-D popover
.antPopover {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .popoverItem {
    display: flex;
    color: #000000;
    gap: 0.4rem;
    border-radius: 5px;
    padding: 5px;
    align-items: center;
    cursor: pointer;

    .icon {
      height: 18px;
      width: 18px;
    }

    &:hover {
      background: rgb(221, 221, 221);
    }
  }

  .border {
    border-top: 1px solid rgb(139, 139, 139);
  }
}

// ANT Button
.ant-btn-default:not(:disabled):hover {
  color: #fff;
  border-color: var(--themeColorGreen);
}

// ANT-D Image Viewer
.imgViewer {
  height: 143px;
  width: 146px;
  display: flex;
  padding: 0.1rem;
  border-radius: 0.3rem;
  border: 1px dashed var(--themeColor);

  .ant-image {
    border-radius: 0.3rem;
    overflow: hidden;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.ant-upload {
  border-radius: 50rem !important;
  height: 130px !important;
  width: 130px !important;
  border-radius: 8px;
  padding: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  .imgBox {
    width: 100%;
    height: 100%;
    border-radius: 50rem;
    overflow: hidden;
    padding: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px;
    }
  }
}

.ant-upload:has(.imgBox) {
  border: none !important;
}

table.ant-picker-content {
  width: 100% !important;
}

// ANT-D table
.table {
  padding-top: .5rem;
  overflow: hidden;

  .ant-table-container {
    scrollbar-color: rgba(0, 0, 0, 0.25) rgba(5, 5, 5, 0.06);
  }

  .avaterBox {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50rem;
    overflow: hidden;
    background-color: var(--pageBackgroundColor);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.2);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .actionBox {
    display: flex;
    gap: 15px;
    justify-content: center;

    .actionBtn {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      transform: scale(1);
      transition: all 0.1s;

      &:hover {
        transform: scale(1.25);

        .icon {
          color: #000000 !important;
        }
      }
    }

    img {
      width: 20px;
    }

    .icon {
      font-size: 20px;
      color: black;
    }
  }

  .ant-table-content {

    table {
      border-spacing: 0 15px;
      padding: 0 1rem;
      // box-shadow: 4px 4px 20px 0px #00000040;
      // background-color: var(--pageBackgroundColor);
      background-color: #fff;
 
    }
  }

  // row hover bg color
  .ant-table-thead>tr {
    border-radius: 1rem;
    background: linear-gradient(90.28deg, #ffc01e 0%, #ffdb7e 98.36%);
    color: var(--secondaryTextColor);
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);

    td {
      &:first-child {
        border-radius: 8px 0 0 8px !important;
      }

      &:last-child {
        border-radius: 0 8px 8px 0 !important;
      }
    }

    .ant-table-column-has-sorters {
      .ant-table-column-sorter-inner {
        .active {
          color: #0a1c29;
        }
      }

      &:hover {
        background: none;
      }

      background: none;
    }
  }

  .ant-table-thead>tr>th {
    border: none;
    background-color: transparent;
    color: var(--primaryTextColor);
    height: 78px;

    &::before {
      content: none !important;
      border-color: transparent;
    }
  }

  .ant-table-column-sorters {
    justify-content: unset;

    .ant-table-column-title {
      flex: none;
    }
  }

  .ant-table-tbody {
    // background-color: var(--pageBackgroundColor);
    background-color: #fff;

    tr {
      border-radius: 8px;
      box-shadow: 4px 4px 20px 0px #00000040;

      td {
        background-color: white;
        background: #ffffff;
        height: 80px;

        &:first-child {
          padding: 0 40px;
          border-radius: 8px 0 0 8px;
        }

        &:last-child {
          border-radius: 0 8px 8px 0;
        }
      }
    }
  }

  // search field || button
  .custom-table .ant-input-search-button {
    background-color: var(--themeColor) !important;
    color: var(--themeColorGreen) !important;
    height: 35px !important;
  }

  .custom-table .ant-input-affix-wrapper {
    border: 1px solid var(--border) !important;
    border-radius: 5px !important;
    height: 35px !important;
    padding: 0px 10px !important;
    flex-grow: 1 !important;
  }

  .ant-input-affix-wrapper:focus,
  .ant-input-affix-wrapper-focused {
    box-shadow: 0 0 0 2px #0064001f;
  }

  // row hover bg color
  .ant-table-cell-row-hover {
    cursor: pointer;
    background-color: #ededed !important;
    color: #101010;

    .actionBtn {
      .icon {
        color: var(--primaryTextColor);
      }
    }
  }

  // pagination button
  .ant-pagination .ant-pagination-item-active a {
    color: var(--primaryTextColor);
  }

  .ant-pagination-item-active {
    border-color: var(--primaryColor);

    &:hover {
      border-color: var(--primaryColor);
    }
  }

  td.ant-table-column-sort {
    background-color: unset;
  }

  .noRole {
    color: var(--danger);

    &:hover {
      color: white;

      .icon {
        color: var(--primaryTextColor);
      }
    }

    .ant-table-cell-row-hover {
      background-color: #d73a3a !important;
      color: white;
    }
  }

  .centerAntTableHeader {
    .ant-table-column-sorters {
      justify-content: center;
    }
  }
}

.ant-input-affix-wrapper-lg {
  border: transparent;
}

// ANT-D Modals :
.ant-radio-wrapper .ant-radio-checked .ant-radio-inner {
  background-color: var(--themeColor) !important;
  border-color: var(--themeColor) !important;
}

.ant-radio-wrapper:hover .ant-radio-inner {
  border-color: var(--themeColor);
}

.ant-radio:hover .ant-radio-inner {
  border-color: var(--themeColor) !important;
}

.ant-radio-input:focus .ant-radio-inner {
  border-color: var(--themeColor) !important;
}

.radioMain {
  border-color: var(--themeColor) !important;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
  color: #fff;
  border-color: #ffc01e;
  // background-color: var(--themeColor);
}

.ant-image-preview-root .ant-image-preview-mask {
  background-color: rgba(0, 0, 0, 0.85);
}

.ant-modal {
  .deleteModel {
    display: flex;

    .titleBox {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      font-size: 1rem;

      .icon {
        color: red;
        font-size: 1.5rem;
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: end;

    .ant-btn {
      background: var(--themeBackGreenGradient);
      box-shadow: var(--themeBackGreenBoxShadow);
      width: 100px;
      color: white;
      border: transparent;
      font-size: small;

      &:nth-child(2) {
        background: var(--themeBackRedGradient);
        box-shadow: var(--themeBackRedBoxShadow);
        border: none;
      }
    }
  }
}

// AllSupportUser Modal

.emailReplyModel {

  .heading {
    display: flex;
    align-items: center;

    .mailLogo {
      background-color: var(--primaryColor);
      width: 60px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      margin-right: 16px;
    }

    p {
      font-size: 15px;


      a {
        color: var(--primaryColor);
        font-size: 12px;
      }
    }
  }

  .dataPara {
    margin-top: 10px;
    font-size: 13px;
  }



  .ant-input-outlined {

    &:hover {
      border-color: var(--primaryColor);
    }
  }

  TextArea {
    margin-top: 12px;

    &:hover {
      border-color: var(--primaryColor);
    }
  }


  .modelButtons {
    margin-top: 20px;

    Button {
      margin-right: 10px;
    }
  }
}

.ql-snow .ql-picker-label {
  padding-right: 15px;
}

.generalSpacing {
  height: 100%;
  position: relative;


  .flexLineSpace {

    padding: 16px 30px 16px 32px;

    @media only screen and (max-width:600px) {
      padding: .5rem 0 0 0;
    }



    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .heading {
      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }

  }
}

.customheading {
  margin-left: 1%;
}
.ant-modal-footer button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-default, .ant-modal-footer button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-primary {
  max-height: 30px;
  height: 30px;
  min-height: 30px;
  // width: 100%;
  padding: 20px 35px;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: ease-in-out 0.2s;
}
