.reviewModalContainer {

    display: flex;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 500px;
    border-radius: .3rem;
    background: white;
    position: relative;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.22);

    .header {
        background: linear-gradient(93.95deg, #FFC01E 21.88%, #FEE198 100%);
        width: 100%;
        height: 120px;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        left: 0;
        right: 0;

        .likeThumb {
            position: absolute;
            left: 40%;
            top: 45px;
            background: white;
            border-top-left-radius: 50%;
            border-top-right-radius: 50%;
            border-bottom-left-radius: 40%;
            border-bottom-right-radius: 40%;
            width: 110px;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .reviewOrder {
        font-size: 22px;
        font-weight: 700;
        z-index: 10;
    }

    .ratingStarts {
        .rate {
            font-size: 2rem;
        }
    }

    .textAreaReview {
        width: 80%;
        display: flex;
        justify-content: center;
        align-items: center;

        textarea {
            width: 100%;
            border: 1px solid rgba(0, 0, 0, 0.3);
            padding: 12px 20px;
            border-radius: 5px;
            height: 150px;
        }
    }

    // .submitBtn {
    //     width: 100%;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin-top: .5rem;

    //     button {
    //         padding: 1rem 1.5rem;
    //         background: #FEE198;
    //         color: black;
    //         border-radius: 5px;
    //         transition: all .2s;

    //         &:hover {
    //             background: #FFC01E;

    //         }
    //     }
    // }

}