.main {

    .form-body {
        margin: 2rem 0;
        padding: 2rem 3rem;
        background-color: #fff;
        border-radius: 10px;
        border-radius: .8rem;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        gap: 1rem;

        .heading {
            padding-bottom: 2rem;
            margin: auto;
            display: flex;
            h2{
                font-size: 1.7rem;
                font-weight: 600;
            }

            .icon {
                font-size: 2.5rem;
                cursor: pointer;
            }
        }

        .top-cards {
            display: flex;
            width: 100%;
            gap: 3rem;
            justify-content: space-between;

            .card {
                padding: 2rem 3rem;
                border-radius: 5px;
                background-color: #F4F4F4;
                width: 100%;

                &:hover {
                    background-color: #FCD635;
                }

                .icon-box {
                    display: inline-flex;
                    justify-content: flex-start;
                    flex-wrap: nowrap;
                    flex-direction: row;
                    gap: 1rem;

                    h3 {
                        font-weight: 400;
                        font-size: 15px;
                        font-family: 'Poppins';
                        color: #42464E;
                    }

                    .icon {
                        margin: auto;
                        line-height: 10px;
                    }
                }

                .amount {
                    h1 {
                        color: #000;
                        font-weight: 600;
                        font-family: 'Poppins';
                        font-size: 30px;
                        line-height: 48px;
                    }
                }

                p {
                    font-family: 'Poppins';
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                }

            }

            .card.active {
                background-color: #FCD635;
            }
        }

        .form-section {
            padding: 4rem 0 2rem 0;

            .form-heading {
                h2 {
                    font-family: 'Poppins';
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 30px;
                    letter-spacing: 0em;
                    text-align: lef;
                    color: #000;
                }
            }

            .form {
                max-width: 100%;
                width: 100%;
                gap: 0.7rem 0;
                display: grid;

                .form-row {
                    display: flex;
                    max-width: 100%;
                    justify-content: space-between;
                    gap: 2rem;

                    .input-group {
                        width: 100%;

                        .ReactFlagsSelect-module_selectBtn__19wW7 {
                            // border: none;
                            // box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%);
                            height: 71px;
                            padding: 0px 25px;
                            padding-top: 0px;
                            background: #ffffff;
                            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 9px;
                        }


                        .ant-select-selector {
                            // padding: 20.5px 15px;
                            // border: none;
                            // border-radius: 4px;
                            // box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%);
                            height: 71px;
                            padding: 0px 25px;
                            padding-top: 0px;
                            background: #ffffff;
                            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 9px;
                            align-items: center;

                        }

                        span.ant-select-selection-placeholder {
                            font-size: 16px;
                            color: #000000;
                        }

                        .withdrawalInput {
                            // padding: 9px 10px;
                            // border: none;
                            // border-radius: 4px;
                            // box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%);
                            width: 100%;
                            min-width: 10rem;
                            height: 71px;
                            padding: 12px 25px;
                            background: white;
                            border: 1px solid #f3f3f3;
                            background: #ffffff;
                            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 9px;

                        }
                    }
                }

                .submit-button {
                    padding: 1.5rem;
                    text-align: center;
                    margin: auto;
                }
            }
        }
    }


}

.custom-react-flags-select {
    border: 0.7px solid #fdfdfd;
    transition: border-color 0.3s ease;
}



/* Remove the inner border of the select field */
.custom-react-flags-select .css-1hb7zxy-IndicatorsContainer {
    border: none !important;
}


#gender .ant-select-selector .ant-select-selection-item {
    display: flex;
    align-items: center;
    border: none;
}

.ant-select-selector:hover {
    border: none !important;
}

.selector {
    border: transparent;
    box-shadow: none;
    display: flex;
    width: 100%;

    @media screen and (max-width:800px) {
        font-size: 14px;
    }

    @media screen and (max-width:500px) {
        font-size: 12px;
    }
}

.inputselect {
    width: 100%;
    min-width: 40rem;
    height: 71px;
    padding: 12px !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    border: 0.7px solid #c9c9c9;
    gap: 0.3rem;
    display: flex;
    align-items: center;

    .inputselect:hover {
        border-color: #fcd117 !important;

    }

    .dateicon {
        @media screen and (max-width:500px) {
            display: none;
        }
    }

    @media screen and (max-width:800px) {
        height: 40px !important;
        padding: 6px !important;
        font-size: 14px;
    }

    @media screen and (max-width:500px) {
        font-size: 12px;
    }

    .iconInfo {
        @media screen and (max-width:500px) {
            display: none;
        }
    }

    .ant-select-dropdown .ant-select-item-option-content {
        @media screen and (max-width:800px) {
            font-size: 14px;
        }

        @media screen and (max-width:500px) {
            font-size: 12px;
        }
    }

    .ant-select-single.ant-select-show-arrow .ant-select-selection-item {
        @media screen and (max-width:800px) {
            font-size: 14px;
        }

        @media screen and (max-width:500px) {
            font-size: 12px;
        }
    }

    .ant-select-multiple .ant-select-selection-item-content {
        @media screen and (max-width:800px) {
            font-size: 14px;
        }

        @media screen and (max-width:500px) {
            font-size: 12px;
        }

    }
}