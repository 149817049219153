.dashboardAllUsersContainer {
  padding-top: 1rem;

  .flexLineSpace {
    padding: 0 30px 0 32px;

    @media only screen and (max-width:600px) {
      padding: 0;
    }



    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .heading {
      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }

    .dashboardBtn {
      width: 120px;

      @media screen and (max-width: 500px) {
        font-size: 13px;
        width: 100px;
      }
    }
  }

  .buttonandFilter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .filterMain {
      height: 40px;
      transition: ease-out 0.3s;

      &:hover {
        scale: 1.01;
      }
    }

    .ant-select-selector {
      border: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 35px;
      text-align: center;
      border-radius: 20px;
      width: 10rem;
      color: var(--themeColor);
      background: linear-gradient(101.19deg, #143745 2.03%, #0a1c29 100%);

      @media screen and (max-width: 500px) {
        width: 7rem;
        padding: 20px 5px;
      }
    }

    .ant-select-selection-item {
      color: var(--themeColor);
    }
  }
}

.customheading {
  margin-left: 1%;
}

.table {
  width: 100%;
}