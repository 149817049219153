.blogForm {
    background: #F9FCFE;
    border-radius: 10px;
    height: min-content;
    padding: 1.5rem 1rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 1rem;

     .form-label {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
    }

    .mb-3 {
        display: flex;
        flex-direction: column;
        gap: .7rem;

        .selectStyleReviewPage {
            background: #FFFFFF;
            box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            border: none;
        }

        .dropdown {
            button {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: .5rem;

                .img {
                    align-items: center;
                    display: flex;
                    gap: .5rem;

                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 4px;
                    }
                }

                .icon {
                    height: 18px;
                    width: 18px;
                }
            }

            .dropdown-menu {
                width: 100%;

                .dropdown-item {
                    display: flex;
                    align-items: center;
                    gap: .2rem;

                    .img {
                        align-items: center;
                        display: flex;
                        gap: .5rem;

                        img {
                            width: 24px;
                            height: 24px;
                            border-radius: 4px;
                        }

                    }
                }

            }

            .btn-secondary {
                --bs-btn-bg: #fff !important;
                --bs-btn-color: #000000;
                --bs-btn-bg: #6c757d;
                --bs-btn-border-color: #d7dbdf !important;
                --bs-btn-hover-color: none !important;
                --bs-btn-hover-bg: #fff !important;
                --bs-btn-hover-border-color: #d7dbdf !important;
                --bs-btn-focus-shadow-rgb: black;
                --bs-btn-active-color: #000000;
                --bs-btn-active-bg: #fff !important;
                --bs-btn-active-border-color: #d7dbdf !important;
                --bs-btn-shadow: inside 0 3px 15px rgba(172, 28, 28, 0.125);

            }
        }
    }


    .mb-3 {
        display: flex;
        flex-direction: column;

        .switch {
            position: relative;
            display: inline-block;
            width: 45px;
            height: 25px;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #ccc;
            -webkit-transition: .4s;
            transition: .4s;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 23px;
            width: 24px;
            left: 2px;
            bottom: 1px;
            background-color: white;
            transition: 0.4s;
        }

        input:checked+.slider {
            background-color: var(--themeColorGreen);
        }

        input:focus+.slider {
            box-shadow: 0 0 1px var(--themeColorGreen);
        }

        input:checked+.slider:before {
            -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
            transform: translateX(18px);
        }

        .slider.round {
            border-radius: 34px;
        }

        .slider.round:before {
            border-radius: 50%;
        }

        .ant-select-selector {
            height: auto !important;
            padding: 5px !important;
        }
    }

}