:root {
  --line-thickness: 0.15em; /* Reduced thickness for smaller size */
  --glass-size: 40%; /* Adjusted size */
  --icon-height: 3rem; /* Reduced height */
  --transition-speed: 0.35s;
  --timing-function: cubic-bezier(0.77, 1.55, 0.77, 1.13);
  --icon-color: #0a1c29; /* Changed to black */
  --font-size: 1em; /* Adjusted font size */
}

* {
  box-sizing: border-box;
}

.search-icon {
  box-sizing: border-box;
  max-width: 20em; /* Adjusted max width */
  transition: all var(--transition-speed) linear,
    border-color 0s linear var(--transition-speed);
  position: fixed;
  left: 30em; /* Adjusted position to move right */
  margin: auto;
  border: solid var(--line-thickness);
  border-color: rgba(0, 0, 0, 0); /* Changed to black */
  padding: 0.25em;
  margin-bottom: 1%;
}
.search-icon:hover {
  cursor: pointer;
}

.search-icon,
.search-icon .search-icon-wrapper {
  width: 2.3rem;
  height: 2.3rem;
  top: 24px;
  right: 0;
  border-radius: 100px;
}

.search-icon-input {
  background: #fff; /* Changed to white */
  text-align: left;
  outline: none;
  display: block;
  border: none;
  width: calc(
    100% - (var(--icon-height) / 2 + 0.5rem)
  ); /* Adjusted width calculation */
  margin-right: 3rem; /* Adjusted margin */
  height: 100%;
  border-radius: 100px;
  transition: all var(--transition-speed) linear;
  font-size: var(--font-size);
  padding: 0 0.5em 0 1em;
  color: #000000; /* Changed to black */
}

.search-icon-input::placeholder {
  color: rgba(0, 0, 0, 0.45); /* Changed to black */
}

.search-icon-wrapper {
  position: absolute;
  margin: auto 0;
  transform: rotate(-45deg);
  transition: all 0s linear;
}

.search-icon-wrapper:hover {
  cursor: pointer;
}

.search-icon-glass {
  width: var(--glass-size);
  height: var(--glass-size);
  border: solid var(--line-thickness);
  border-color: var(--icon-color);
  border-radius: 100px;
  margin: 0 auto;
  position: relative;
  transition: all var(--transition-speed) var(--timing-function)
      var(--transition-speed),
    border-color 0s linear var(--transition-speed);
}

.search-icon-handle {
  height: calc(100% - var(--glass-size));
  width: var(--line-thickness);
  margin: 0 auto;
  background: var(--icon-color);
  position: absolute;
  border-radius: 0 0 100px 100px;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all var(--transition-speed) var(--timing-function);
  transition-delay: var(--transition-speed);
}

.search-icon-handle::after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background: var(--icon-color);
  transition: rotate(0deg);
  transition: all var(--transition-speed) var(--timing-function);
  transition-delay: 0s;
}

.search-icon.open {
  width: calc(100vw - 1em);
  border-color: var(--icon-color);
  transition-delay: var(--transition-speed);
  top: 43px;
}

.search-icon.open .search-icon-input {
  transition-delay: var(--transition-speed);
}

.search-icon.open .search-icon-wrapper .search-icon-glass {
  width: 100%;
  height: 100%;
  transition: all var(--transition-speed) var(--timing-function) 0s,
    border-color 0s linear var(--transition-speed);
  border-color: rgba(0, 0, 0, 0);
}

.search-icon.open .search-icon-wrapper .search-icon-handle {
  bottom: calc(50% - (100% - var(--glass-size)) / 2);
  border-radius: 100px;
  transition-delay: 0s;
  margin-bottom: 32px;
}

.search-icon.open .search-icon-wrapper .search-icon-handle::after {
  transition-delay: var(--transition-speed);
  transform: rotate(90deg);
}
