.dashboardContainer {
    display: flex;
    max-height: 100vh;

    .sider {
        height: 100vh;
        display: grid;
        grid-template-rows: auto 45px;
        background-color: var(--secondaryColor);
        box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.2);
        overflow: hidden;
        transition: all .3s;

        .scrollbar-track-y{
            opacity: 0;
        }

        .ant-layout-sider-children {
            display: flex;
            flex-direction: column;

            .logoBox {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 1.5rem 0;
                height: 110px;
                transition: all .5s;

                img {
                    width: 150px;
                    height: 100%;
                    object-fit: contain;
                }
            }

            ::-webkit-scrollbar {
                width: 0px !important;
            }

            .ant-menu-root {
                height: calc(100vh - 178px);
                background-color: inherit;
                border: none;
                // overflow-y: scroll;
                color: var(--secondaryIconColor);

                .ant-menu-item {
                    font-size: 1.1rem;
                    width: 100%;
                    margin: 1rem 0;
                    border-radius: 0;
                    color: var(--secondaryIconColor);

                    li {
                        color: var(--secondaryIconColor);
                    }

                    .ant-menu-item-icon {
                        font-size: inherit;
                    }

                    &:hover {
                        color: inherit;
                        background-color: #111317;
                    }

                    &:focus-visible {
                        background-color: #111317;
                        outline: none;
                    }
                }

                .ant-menu-item-selected {
                    background-color: inherit;
                    color: var(--primaryColor);
                    position: relative;
                    background: linear-gradient(270deg, #282C35 0%, #111317 99.72%);

                    &::before {
                        position: absolute;
                        content: "";
                        width: 2px;
                        border-radius: 4px 0 0 4px;
                        background-color: var(--primaryColor);
                        top: 1px;
                        bottom: 1px;
                        left: 0;
                    }

                    &:hover {
                        color: var(--primaryColor);
                    }
                }
            }
        }

        .ant-layout-sider-trigger {
            padding: .2rem .5rem;
            cursor: pointer;
            transition: all .3s;

            @media screen and (max-width:800px) {
                display: none;
            }

            .trig {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.2rem;
                font-weight: bold;
                box-sizing: border-box;
                background-color: var(--primaryColor);
                border-radius: 10rem;
                background: linear-gradient(93.95deg, #FFC01E 21.88%, #FEE198 100%);
            }
        }
    }

    .rightContainer {
        width: 100%;
        height: 100vh;
        max-height: 100vh;
        overflow: hidden;
        position: relative;
        display: flex;
        flex-direction: column;


        .rightBox {
            height: 100%;
            overflow-y: scroll;
            margin: 1rem 0;
            // padding: 0 1rem;
            box-sizing: border-box;

            .noTableBox{
                padding: 0 1rem;
            }
        }
    }
}