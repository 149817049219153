// .ant-modal-footer button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-default{
//     background: linear-gradient(275.12deg,
//     #b40901 5.38%,
//     #fb4b4d 94%);
//     box-shadow: 0px 2px 4px rgba(239, 64, 64, 0.8);
// }
// .ant-modal-footer button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-primary{
//     background: linear-gradient(274.64deg,
//     #02a800 5.4%,
//     #1ad56c 94.36%);
//     box-shadow: 0px 2px 4px rgba(25, 211, 105, 0.8);
// }

.ant-modal-footer button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-default:hover , .ant-modal-footer button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-primary:hover {
    color: #fff !important;
    scale: 1.03 !important;
}
.ant-modal-footer button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-default
{
    background: linear-gradient(274.64deg,
    #02a800 5.4%,
    #1ad56c 94.36%) !important;
    box-shadow: 0px 2px 4px rgba(25, 211, 105, 0.8) !important;
}
.ant-modal-footer button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-default:hover
{
    background: linear-gradient(274.64deg,
    #02a800 5.4%,
    #1ad56c 94.36%) !important;
    box-shadow: 0px 2px 4px rgba(25, 211, 105, 0.8) !important;
}