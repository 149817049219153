.saleschartContainer {
    padding: .5rem;
    height: 100%;
    width: 100%;

    .chart {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}