.primary-button {

    button {
        width: 150px;
        height: 50px;
        font-size: 15px;
        cursor: pointer;
        color: var(--dark);
        font-family: 'Poppins';
        font-weight: 400;
        border-radius: 5px;
        transition: all 0.3s ease;
        box-shadow: inset 2px 2px 2px 0px transparent,
            7px 7px 20px 0px rgba(0, 0, 0, .1),
            4px 4px 5px 0px rgba(0, 0, 0, .1);

    }

    .pri-btn {
        box-shadow: none;
        line-height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;

        @media screen and (max-width:"1450px") {
            line-height: 45px;
            // width: fit-content !important;
            height: 38px;

        }

        @media screen and (max-width:"768px") {
            line-height: 45px;
            width: 100px;
            height: 38px;
        }

    }

    .pri-btn span {
        background: linear-gradient(0deg, var(--themeColor) 0%, var(--themeColor) 100%);
        display: block;
        position: absolute;
        width: 150px;
        height: 50px;
        border-radius: 5px;
        margin: 0;
        text-align: center;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        -webkit-transition: all .3s;
        transition: all .3s;

        @media screen and (max-width:"1450px") {
            font-size: 15px;
            font-family: 'Poppins';
            width: 130px;
            height: 45px;

        }

        @media screen and (max-width:"768px") {
            font-size: 13px;
            font-family: 'Poppins';
            width: 100px;
            height: 40px;
        }
    }

    .loading {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .8rem;
    }

    .pri-btn span:nth-child(1) {
        -webkit-transform: rotateX(90deg);
        -moz-transform: rotateX(90deg);
        transform: rotateX(90deg);
        -webkit-transform-origin: 50% 50% -20px;
        -moz-transform-origin: 50% 50% -20px;
        transform-origin: 50% 50% -20px;
    }

    .pri-btn span:nth-child(2) {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        transform: rotateX(0deg);
        -webkit-transform-origin: 50% 50% -20px;
        -moz-transform-origin: 50% 50% -20px;
        transform-origin: 50% 50% -20px;
    }

    .pri-btn:hover span:nth-child(1) {
        -webkit-transform: rotateX(0deg);
        -moz-transform: rotateX(0deg);
        transform: rotateX(0deg);
    }

    .pri-btn:hover span:nth-child(2) {
        color: transparent;
        -webkit-transform: rotateX(-90deg);
        -moz-transform: rotateX(-90deg);
        transform: rotateX(-90deg);
    }
}