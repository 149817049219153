.loginContainer {
    width: 100%;
    height: 100vh;
    position: absolute;
    display: grid;
    gap: 1rem;
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
    background-color: white;

    @media screen and (max-width:700px) {
        display: flex;

    }

    .rightSection {
        background-color: var(--themeColorYellowLight);
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;

        @media screen and (max-width:700px) {
            display: none;
        }

        .madrasaLogo {
            position: absolute;
            bottom: -10px;

            img {
                @media screen and (max-width:1535px) {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .content {
            color: #000000;

            .heading {
                font-size: .9rem;
                margin: .5rem 2rem;
            }

            .para {
                max-width: 500px;
                padding-top: 1.5rem;
                font-size: 1.125rem;

                @media screen and (max-width:1150px) {
                    font-size: 1rem;
                    padding-top: 1rem;
                }

            }
        }

    }

    .leftSection {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;


        form {
            width: 70%;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            align-content: center;
            margin: auto;


            @media screen and (max-width:700px) {
                width: 90%;
            }


            .heading {
                font-weight: 700;
                font-size: 2.5rem;
                text-align: center;
            }

            .flexFields {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 2rem;
                padding-top: .5rem;
                color: #838AA3;

                .name {
                    font-size: small;
                }

                .field {
                    width: 100%;
                    position: relative;
                    color: #838AA3;

                    .name {
                        font-size: small;
                    }

                    .errorMessage {
                        height: 25px;
                        position: absolute;
                        bottom: -30px;
                        left: 5px;
                        font-size: .7rem;
                        color: red;
                    }

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        -webkit-box-shadow: 0 0 0 30px #FCFCFC inset !important;
                    }
                }

                .loginInput {
                    padding: 12px 20px;
                    background: #FCFCFC;
                    border: 1px solid #F3F3F3;
                    border-radius: 8px;

                    input {
                        background-color: inherit;
                    }

                    &:hover {
                        border-color: var(--themeColorGreen);
                    }
                }

                .forgotPass {
                    display: flex;
                    justify-content: end;
                    font-size: 14px;
                    color: var(--danger);
                }

                .loginButton {
                    padding-top: .5rem;

                    .anticon svg {
                        fill: #fff !important;
                    }

                    p {
                        padding-top: 1rem;
                        text-align: center;
                        color: black;

                        a {
                            cursor: pointer;
                            color: #008000;
                        }
                    }

                }
            }

            .authButton {
                padding-top: 10px;
                width: 100%;
                display: flex;
                gap: 1rem;
                justify-content: center;
                flex-direction: column;
                align-items: center;
                // iframe{
                //     width: 500px !important;
                //     #container{
                //         width: 500px;
                //     }
                // }

                .fb,
                .google {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    gap: .5rem;
                    background: rgb(255, 255, 255);
                    padding: 10px;
                    border-radius: 3px;
                    background-color: rgb(255, 255, 255);
                    color: rgba(0, 0, 0, 0.54);
                    box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px;
                    border-radius: 2px;
                    border: 1px solid transparent;
                    font-size: 14px;
                    font-weight: 500;

                    @media screen and (max-width:"500px") {
                        justify-content: center;
                    }
                }

                .fb {
                    background-color: #1877f2;
                    color: #fff;
                }

            }

            .terms {
                padding-top: 5rem;
                font-weight: 400;
                font-size: 0.875rem;
                text-align: center;

                @media screen and (max-width:1024px) {
                    padding-top: 2rem;
                }

                a {
                    font-weight: bold;
                    cursor: pointer;
                    color: var(--themeColorGreen);
                }

            }
        }

    }

}