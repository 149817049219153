.ant-layout,
.ant-layout-footer,
.ant-layout-sider {
  background: none;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-layout .ant-layout-sider-light {
  background: none;
}


// .CallingContainers {
//   height: calc(100vh - 66px);
//   width: 100%;
//   padding: 20px;
//   box-sizing: border-box;

//   .userContainer {
//     height: 85%;
//     align-items: center;
//     gap: 10px;
//     display: flex;
//   }

//   .user-box {
//     background-color: white;
//     position: relative;
//     display: flex;
//     flex-direction: column;
//     align-items: center;
//     border: 1px solid #ccc;
//     justify-content: center;
//     border-radius: 20px;
//     padding: 5px;
//     height: 80%;
//     width: 50%;
//     box-shadow: 0px 0px 15px 2px #00000040;

//     video{
//       border-radius: 20px;
//     }

//     .avater {
//       height: 120px;
//       width: 120px;
//       border-radius: 50rem;
//       overflow: hidden;
//       background-color: rgba(0, 0, 0, 0.25);
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       font-size: 3.5rem;
//       color: white;

//       img {
//         width: 100%;
//         height: 100%;
//         object-fit: cover;
//       }
//     }

//     .mic-icon,
//     .video-icon {
//       position: absolute;
//       border-radius: 100%;
//       padding: 10px 13px;
//       display: flex;
//       justify-content: space-between;
//       align-items: center;
//       gap: 25px;

//       .iconButton {
//         width: 70px;
//         height: 45px;
//         border-radius: 22px;
//         background-color: #F6F6F6;
//         border-color: transparent;
//         box-shadow: 2px 3.197676px 8.988369px 0.497092px #00000040;
//         cursor: pointer;
//       }

//       .usericonButton {
//         width: 57px;
//         height: 53px;
//         border-radius: 26px;
//         background-color: #F6F6F6;
//         border-color: transparent;
//         box-shadow: 2px 3.197676px 8.988369px 0.497092px #00000040;
//       }
//     }

//     .mic-icon {
//       top: 16px;
//       right: 17px;
//     }

//     .video-icon {
//       bottom: 40px;
//       left: 50%;
//       transform: translateX(-50%);
//     }

//   }

//   .actionBar {
//     display: flex;
//     justify-content: center;
//     flex-direction: row;
//     margin-top: '20px';
//     position: relative;
//     align-items: center;

//     .actionButtons {
//       display: flex;
//       justify-content: center;
//       padding: 20px;
//       justify-items: center;
//       align-items: center;
//       gap: 34px;

//       .iconButton {
//         width: 70px;
//         height: 45px;
//         border-radius: 22px;
//         background-color: #F6F6F6;
//         border-color: transparent;
//         box-shadow: 2px 3.197676px 8.988369px 0.497092px #00000040;
//         cursor: pointer;
//       }

//       .leaveButton {
//         color: white;
//         background-color: #FE0034;
//         padding: 12px;
//         height: 47px;
//       }
//     }
//   }

// }


.userContainer{
  display: flex;
  width: 100%;
  gap: 20px;
  padding: 50px 40px;
  height: 100%;
  .user-box{
   display: block;
   width: 50%;
   text-align: center;
   height: 100%;
   padding: 30px 10px;
   border-radius: 15px;
   border: 1px solid #ededed;
   box-shadow: rgb(100 100 111 / 11%) 0px 7px 29px 0px;
   video{
    height: 435px;
    margin-bottom: 10px;
   }
    .avater img{
      width: 450px;
      height: 400px;
      border-radius: 10px;
    }
    .user-name{
      font-size: 20px;
      padding: 10px 0;
    }
    .mic-icon{
      button.usericonButton{
        // padding: 10px 30px;
        height: 40px;
        width: 80px;
        margin: auto;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
      }
    }
    .video-icon{
      button.usericonButton{
        // padding: 10px 30px;
        height: 40px;
        width: 80px;
        margin: auto 10px;
        border-radius: 15px;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
        
      }
    }
  }
}

.actionBar{
  width: 100%;
  padding: 30px 0px;
  .actionButtons{
    display: flex;
    justify-content: center;
    gap: 30px;
    button{
      width: 80px;
      height: 50px;
      box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;
      &:hover{
        img{
        color: #fff;
        filter: brightness(10);
        }
      }
    }
  }
}



// .videoPlayerContainer {
//     .CallingContainer {
//         height: 100vh;
//         width: 100%;
//         padding: 20px;

//         .userContainer {
//             height: 100%;
//             align-items: center;
//             gap: 10px;

//         }

//         .user-box {
//             background-color: white;
//             position: relative;
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             border: 1px solid #ccc;
//             justify-content: center;
//             border-radius: 20px;
//             padding: 10px;
//             margin: 10px auto;
//             height: 40%;
//             width: 70%;
//             // box-shadow: 0px 5.197675704956055px 25.98836898803711px 6.497092247009277px #00000040;


//             .mic-icon,
//             .video-icon {
//                 position: absolute;
//                 border-radius: 100%;
//                 padding: 10px 13px;
//                 display: flex;
//                 justify-content: space-between;
//                 align-items: center;
//                 gap: 25px;
//             }

//             .mic-icon {
//                 top: 16px;
//                 right: 17px;
//             }

//             .video-icon {
//                 bottom: 40px;
//                 left: 50%;
//                 transform: translateX(-50%);
//             }

//         }

//         .actionBar {
//             display: flex;
//             justify-content: center;
//             flex-direction: row;
//             margin-top: '20px';
//             background-color: white;
//             position: relative;
//             align-items: center;
//             border: 1px solid #ccc;
//             border-radius: 20px;
//             // box-shadow: 0px 5.197675704956055px 25.98836898803711px 6.497092247009277px #00000040;

//             .actionButtons {
//                 display: flex;
//                 justify-content: center;
//                 padding: 20px;
//                 justify-items: center;
//                 align-items: center;
//                 gap: 34px
//             }
//         }



//     }

//     .ant-layout,
//     .ant-layout-footer,
//     .ant-layout-sider {
//         background: none;
//     }

//     :where(.css-dev-only-do-not-override-14wwjjs).ant-layout .ant-layout-sider-light {
//         background: none;
//     }


//     :where(.css-dev-only-do-not-override-14wwjjs).ant-btn.ant-btn-icon-only {
//         width: 70px;
//         height: 45px;
//         border-radius: 12px;
//         background-color: #F6F6F6;
//     }
// }