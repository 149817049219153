.ProfileMain{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    .heading{
        text-transform: uppercase;
        display: flex;
        align-items: center;
        gap: 1rem;
        .icon{
            font-size: 2.5rem;
            cursor: pointer;
        }
    }
}