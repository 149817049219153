.walletContainer {
  .ViewBodyMain {
    margin-top: 2rem;
    margin-left: 2%;
    margin-right: 2%;
    padding: 1.5rem;
    background-color: white;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.301);

    @media screen and (max-width: 800px) {
      flex-direction: column-reverse;
    }

    @media screen and (max-width: 500px) {
      padding: .7rem;

    }

    .teacherAmount {
      padding-left: .5rem;
      width: 100%;
      box-shadow: 1px 1px 5px 3px #0f0f0f33;
      background-color: #fcd635;
      display: flex;
      justify-content: space-evenly;
      align-items: flex-start;
      border-radius: 5px;

      .devider {
        color: black;
      }

      .row {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;

        .imgLogo {
          width: 55px;
          height: 55px;
          border-radius: 50%;
          cursor: pointer;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.501);
          display: flex;
          justify-content: center;
          align-items: center;
          background: white;

          .topIcons {
            font-size: 2.5rem;
          }
        }

        .column {
          text-align: center;
          flex: 1;
          margin: 10px;
          padding: 15px;
          position: relative;

          h2 {
            color: black;
          }
        }
      }

      .ant-divider-vertical {
        position: relative;
        top: 2.94em;
        display: inline-block;
        height: 3.9em;
        margin: 0 8px;
        vertical-align: middle;
        border-top: 0;
        border-inline-start: 1px solid black;


        @media screen and (max-width: 715px) {
          display: none !important;
        }
      }

      @media screen and (max-width: 900px) {
        flex-wrap: wrap;

        .row {
          padding: 1rem 0;
          flex-direction: column;

          .imgLogo {
            width: 55px;
            height: 55px;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.501);
            display: flex;
            justify-content: center;
            align-items: center;
            background: white;

            .topIcons {
              font-size: 2.5rem;
            }
          }

          .column {
            text-align: center;
            flex: 1;
            margin: 10px;
            padding: 15px;
            position: relative;

            h2 {
              color: black;
            }
          }
        }

        .ant-divider-vertical {
          position: relative;
          top: 2.94em;
          display: inline-block;
          height: 3.9em;
          margin: 0 8px;
          vertical-align: middle;
          border-top: 0;
          border-inline-start: 1px solid black;

          @media screen and (max-width: 500px) {
            display: none;
          }
        }
      }

    }

    .transitionHistory {
      padding-top: 24px;
      height: 100%;
      width: 100%;

      .transitionGraph {
        padding: 14px 20px 0px 20px;

        .chart {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 320px;
        }
      }
    }

    .recordTable {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1.5rem;

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
        display: block;
        max-width: 100%;
      }

      .table {
        .ant-table-tbody {
          background-color: var(--pageBackgroundColor);

          tr {
            border-radius: 8px;
            box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.25);

            td {
              // height: 260px;
              background-color: white;

              &:first-child {
                border-radius: 8px 0 0 8px;
              }

              &:last-child {
                border-radius: 0 8px 8px 0;
              }
            }
          }
        }
      }

      .ant-row {
        flex-direction: row;
      }

      .balanceCard {
        padding-top: 2.6rem;

        .ant-card {
          background: linear-gradient(#ffc01e, #f7a928);
          box-shadow: 1px 1px 5px 3px #0f0f0f33;
          border: none;
          margin: auto;
          width: 100;
          max-width: 400px;

          @media screen and (max-width: 500px) {
            width: auto !important;
          }
        }

        .ant-card-body {
          padding: .8rem;

        }

        .cardHeading {
          width: 90%;
          margin-left: 13px;



          .balanceHeading {
            color: white;
            font-size: 36px;
            font-weight: 600;
            line-height: 42.66px;
          }

          .activeBalance {
            color: var(--themeColorYellowLight);
            font-size: 18px;
            font-weight: 500;
            line-height: 21.33px;
          }

          @media screen and (max-width: 500px) {
            margin: 0;


            .balanceHeading {
              font-size: 26px;
              font-weight: 500;
            }

            .activeBalance {
              color: var(--themeColorYellowLight);
              font-size: 15px;
              font-weight: 400;
            }
          }
        }

        .cardDetails {
          display: flex;
          flex-direction: column;
          width: 90%;
          margin-left: 13px;
          gap: 1rem;
          margin-top: 20px;
          margin-bottom: 20px;

          @media screen and (max-width: 500px) {
            margin: 0;
            width: 100%;
          }

          .detailDiv {
            align-items: center;
            display: flex;
            justify-content: space-between;
            color: white;

            .logoHeading {
              align-items: center;
              display: flex;
              justify-content: space-between;
              gap: 14px;

              .iconBox {
                background: white;
                width: 27px;
                height: 27px;
                border-radius: 5px;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                  font-size: 1.2rem;
                }
              }
            }

            @media screen and (max-width: 500px) {
              margin-top: .5rem;

              h5 {
                display: flex;
                font-size: 13px;
                font-weight: 400;
              }

              .logoHeading {
                gap: 5px;

                .iconBox {
                  width: 25px;
                  height: 25px;
                  border-radius: 5px;

                  .icon {
                    font-size: 1rem;
                  }
                }
              }
            }


          }
        }

        .cardButton {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            background: none;
            font-weight: 500;
            width: 300px;
            height: 48px;
            padding: 12px, 20px, 12px, 20px;
            background: white;
            color: #f7a928;
            border-radius: 10px;
            font-size: 20px;
            line-height: 23.7px;
            margin-left: 0.5rem;
            transition: ease-in-out 0.2s;

            &:hover {
              scale: 1.01;
            }
          }

          .icon {
            font-size: 30px;
          }

          @media screen and (max-width: 500px) {
            button {
              margin-top: .5rem;
              font-size: 15px;
              line-height: 0px;
              margin-left: 0;
              width: 300px;
              height: 40px;
              padding: 12px, 20px;
            }

            .icon {
              font-size: 22px;
            }
          }
        }
      }
    }
  }
}

.customhead {
  margin-left: 3%;
}