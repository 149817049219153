
.ScheduleMain {
    background: #FFFFFF;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 2rem;

    .head {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .iconSchedule {
            color: var(--dark);
        }

        .headingInfo {
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            @media screen and (max-width:800px) {
                font-size: 14px !important;                
            }
        }

    }

    .inputMain {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        gap: 1rem;
        width: 100%;

        .inputFields {
            gap: 1rem;
            display: flex;
            width: 100%;
            flex-direction: column;


            .ant-select-selector {
                padding: 10px;
            }

            .radiobtn {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .TypeHeading {
                    font-size: 14px;
                    color: var(--dark);
                    font-weight: 500;
                    padding: 0 2rem;
                    @media screen and (max-width: 1100px) {
                        padding: 0 0.5rem !important;                        
                    }
                    @media screen and (max-width: 1000px) {
                        display: none !important;                        
                    }
                }
                .radioFields{
                    display: flex;
                    @media screen and (max-width:600px) {
                        flex-direction: column;                        
                    }
                    .radioMain{
                        @media screen and (max-width:800px) {
                            font-size: 12px;
                        }
                    }
                }



            }

            .rateField {
                max-width: 100%;
                width: 100%;
                height: 71px;
                gap: 1rem;
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                border-radius: 9px;
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;

                .ant-input-affix-wrapper:hover {
                    border-color: var(--border);
                }

                .ant-select-single {
                    border: 1px solid var(--border);
                    border-radius: var(--radius);
                }

                .ant-select-selector:hover {
                    border-color: transparent !important;
                }

                .ant-select-selector {
                    height: -webkit-fill-available;
                    align-items: center;

                }

                .TypeHeading {
                    font-size: 14px;
                    color: var(--dark);
                    font-weight: 500;
                    padding: 0 2rem;
                    @media screen and (max-width: 1100px) {
                        padding: 0 0.5rem !important;                        
                    }
                    @media screen and (max-width: 1000px) {
                        display: none !important;                        
                    }
                }
            }

            .rate {
                display: flex;
                align-items: center;
                padding-left: 0.5rem;
            }

            .hourlyratesign {
                display: flex;
                width: 15%;
                gap: 1rem;
                height: 3rem;
                @media screen and (max-width: 1600px) {
                    min-width: 15rem;                        
                }
                
                .input {
                    width: 100%;
                    padding: 12px !important;
                    background: white;
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 9px;
                    gap: 0.3rem;
                    height: 48px;
                    @media screen and (max-width: 1500px) {
                        width: 7rem !important;                        
                    }
                    @media screen and (max-width: 420px) {
                        width: 4.5rem !important;                        
                    }

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        -webkit-box-shadow: 0 0 0 30px white inset !important;
                    }
                }
            }

            .field {

                .input {
                    width: 100%;
                    min-width: 10rem;
                    height: 71px;
                    padding: 12px !important;
                    background: white;
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 9px;
                    gap: 0.3rem;

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        -webkit-box-shadow: 0 0 0 30px white inset !important;
                    }

                    .iconInfo {
                        color: var(--dark);
                    }

                    .ant-select-selector {
                        border: none;
                        display: flex;
                        justify-content: center;
                        font-size: 1rem;
                        display: flex;
                        align-items: center;
                        height: -webkit-fill-available;
                        scroll-behavior: smooth;
                    }
                }
            }
        }

        .ant-modal .ant-modal-footer .ant-btn:hover {
            color: white;
            border: transparent;
        }

        .yellowGraBtn {
            width: 7rem;
            display: flex;
            align-self: flex-end;
        }

        
    }
}