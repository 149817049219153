@import url("https://fonts.googleapis.com/css2?family=Arial&family=Comic+Sans+MS&family=Courier+New&family=Georgia&family=Helvetica&family=Lucida+Sans+Unicode&display=swap");

.ql-font-arial {
  font-family: Arial, sans-serif;
}

.ql-font-comic-sans {
  font-family: "Comic Sans MS", cursive, sans-serif;
}

.ql-font-courier-new {
  font-family: "Courier New", Courier, monospace;
}

.ql-font-georgia {
  font-family: Georgia, serif;
}

.ql-font-helvetica {
  font-family: Helvetica, sans-serif;
}

.ql-font-lucida {
  font-family: "Lucida Sans Unicode", "Lucida Grande", sans-serif;
}

.ql-font-tahoma {
  font-family: Tahoma, sans-serif;
}

.ql-font-times-new-roman {
  font-family: "Times New Roman", Times, serif;
}

.ql-font-trebuchet {
  font-family: "Trebuchet MS", Helvetica, sans-serif;
}

.ql-font-verdana {
  font-family: Verdana, Geneva, sans-serif;
}

/* editor.css */
.ql-font span[data-value="arial"]::before {
  content: "Arial";
}

.ql-font span[data-value="comic-sans"]::before {
  content: "Comic Sans MS";
}

.ql-font span[data-value="courier-new"]::before {
  content: "Courier New";
}

.ql-font span[data-value="georgia"]::before {
  content: "Georgia";
}

.ql-font span[data-value="helvetica"]::before {
  content: "Helvetica";
}

.ql-font span[data-value="lucida"]::before {
  content: "Lucida";
}

.ql-font span[data-value="tahoma"]::before {
  content: "Tahoma";
}

.ql-font span[data-value="times-new-roman"]::before {
  content: "Times New Roman";
}

.ql-font span[data-value="trebuchet"]::before {
  content: "Trebuchet MS";
}

.ql-font span[data-value="verdana"]::before {
  content: "Verdana";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="arial"]::before {
  content: "Arial";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="comic-sans"]::before {
  content: "Comic Sans MS";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="courier-new"]::before {
  content: "Courier New";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="georgia"]::before {
  content: "Georgia";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="helvetica"]::before {
  content: "Helvetica";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="lucida"]::before {
  content: "Lucida";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="tahoma"]::before {
  content: "Tahoma";
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-item[data-value="times-new-roman"]::before {
  content: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="trebuchet"]::before {
  content: "Trebuchet MS";
}

.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="verdana"]::before {
  content: "Verdana";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="arial"]::before {
  content: "Arial";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="comic-sans"]::before {
  content: "Comic Sans MS";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="courier-new"]::before {
  content: "Courier New";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="georgia"]::before {
  content: "Georgia";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="helvetica"]::before {
  content: "Helvetica";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="lucida"]::before {
  content: "Lucida";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="tahoma"]::before {
  content: "Tahoma";
}

.ql-snow
  .ql-picker.ql-font
  .ql-picker-label[data-value="times-new-roman"]::before {
  content: "Times New Roman";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="trebuchet"]::before {
  content: "Trebuchet MS";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="verdana"]::before {
  content: "Verdana";
}
/* editor.css */
/* CSS */
.editor-container {
  /* Your styles for the editor container */
  position: relative; /* Ensure it's a positioned container */
}
