.videoContainers {
  width: 100%;
  height: -webkit-fill-available;

  *{
    transition: all 1s;
  }

  .invalid {
    font-size: 2rem;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    text-align: center;
    gap: 1rem;
  }

  .ant-layout-sider {
    background-color: transparent;
  }

}