.close {
    position: absolute;
    right: 20px;
    font-size: 1.5rem;
    cursor: pointer;

    .icon {
        fill: var(--themeColor);
    }
}

.profilePageContainer {
    margin: .5rem;
    padding: 1.5rem;
    background-color: white;
    border-radius: .8rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.301);

    .wrapContainer {
        display: flex;
        flex-direction: column;
        gap: 3rem;
    }

    .flexFields {
        display: flex;
        flex-direction: column;
        gap: 1rem;


    }

    .registerInput {
        width: 100%;
        height: 70px;
        padding: 12px 30px;
        background: white;
        border: 1px solid #F3F3F3;
        border-radius: 9px;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);

        input {
            background-color: inherit;
        }

        &:hover {
            border-color: var(--themeColorGreen);
        }
    }

    .registerSelector {
        .ant-select-selector {
            height: 70px;
            padding: 12px 30px;
            padding-top: 20px;
            background: #FCFCFC;
            border: 1px solid #F3F3F3;
            border-radius: 9px;
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        }
    }

    .ant-input-prefix {
        margin-right: .8rem;
    }

    .fields {
        color: #42464E;
        width: 100%;
        display: flex;
        gap: 1rem;

        .field {
            width: 100%;
            position: relative;
            color: #838AA3;

            .name {
                font-size: small;
            }

            .errorMessage {
                height: 25px;
                position: absolute;
                bottom: -30px;
                left: 5px;
                font-size: .7rem;
                color: red;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus,
            input:-webkit-autofill:active {
                -webkit-box-shadow: 0 0 0 30px #FCFCFC inset !important;
            }
        }

    }

    .ant-space-vertical {
        width: 100% !important;
    }

    .registerButton {
        padding-top: .5rem;
        display: flex;
        justify-content: center;


        .anticon svg {
            fill: #fff !important;
        }

        p {
            padding-top: 1rem;
            text-align: center;
            font-size: 0.875rem;

            a {
                font-weight: bold;
                color: var(--themeColorGreen);
            }
        }

        .yellowBtn {
            width: 267px;
            height: 71px;
            background: linear-gradient(94.04deg, #FFC01E 22.18%, #FFDB7E 100%);
            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
            border-radius: 76px;
            width: 200px;

        }

    }
}