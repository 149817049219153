.dashboardAllUsersContainer {

  .table {
    .tag {
      left: -16px !important;
    }

    .tag2 {
      left: -11px;
    }

    .centered-column {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .start-column {
      width: 35%;
    }
  }
}
.dashboardBtnclose{
  background: linear-gradient(275.12deg,
  #b40901 5.38% ,
  #fb4b4d 94%) !important;
  box-shadow: 0px 2px 4px rgba(239, 64, 64, 0.8) !important;
  max-height: 30px;
  height: 30px;
  min-height: 30px;
  width: 100%;
  padding: 20px 35px;
  border-radius: 20px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: ease-in-out 0.2s;
}
.dashboardBtn:hover, .dashboardBtnclose:hover {
  color: #fff !important;
  border: none;
  scale: 1.03 !important;
}