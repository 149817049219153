.dashboardAllUsersContainer {
    .table {
        .centered-column {
            display: flex;
            justify-content: center;
        }
    }
}

.ant-table-content {
    overflow: hidden !important;
}