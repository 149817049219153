.TransactionContainer{
    margin: .5rem;
    padding: 1.5rem;
    background-color: white;
    border-radius: .8rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.301);

    .heading{
        align-items: center;
        display: flex;
    }
    .icon{
        font-size: 2.5rem;
        cursor: pointer;
    }
    .creditCard{
        position: absolute;
        right: 34px;
        top: 15px
    }
    .cardImg{
        height: 28px;
        width: 28px;
    }
    .shippingPhone{
        display: flex;
        align-items: center;
        .shippingIcon{
            margin-right: 8px;
        }
    }
    .centered-column {
        display: flex;
        justify-content: center;
      }
   

}
@media screen and (max-width:1800px) {
    .ant-col-xl-19{
        max-width: 100%;
    }
    .ant-row{
        flex-direction: column;
    }
}
@media screen and (max-width:500px) {
    .ant-card{
        width: 240px !important;
    }    
}