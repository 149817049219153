.navbarContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 50;
    padding: 0 52px 0 48px;

    @media only screen and (max-width:600px) {
        padding: 0 15px;
    }

    .navbarBox {
        width: 100%;
        padding: 13px 0 0 0;
        display: flex;
        gap: .5rem;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width:"768px") {
            // padding: 0px 10px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.39);

        }

        .leftSide {
            display: flex;
            flex-direction: column;

            .welcome {
                font-size: 15px;
                font-weight: 600;
                line-height: 23px;
                letter-spacing: 0.50em;
            }

            .dash {
                font-size: 28px;
                font-weight: 600;
            }

            .name {
                font-size: 30px;
                font-weight: 600;
                line-height: 45px;
                letter-spacing: 0em;
            }

            @media only screen and (max-width:460px) {
                .welcome {
                    font-size: 13px;
                    font-weight: 500;
                    line-height: 23px;
                    letter-spacing: 0.20em;
                }

                .dash {
                    font-size: 18px;
                    font-weight: 600;
                }

                .name {
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 25px;
                    letter-spacing: 0em;
                }
            }

        }

        .rightSide {
            display: flex;
            align-items: center;
            gap: 1rem;

            .inputfield {
                position: relative;
                background-color: white;
                box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.06);
                border-radius: 8px;
                display: flex;
                align-items: center;
                padding: 0px 10px;
                height: 40px;

                input {
                    width: 200px;
                    padding: 8px 10px;
                    border-radius: 8px;
                    transition: width .7s;

                    &:focus-within {
                        width: 250px;
                    }

                    @media screen and (max-width:640px) {
                        display: none;
                    }
                }

                .icon {
                    font-size: .85rem;
                    color: black;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    @media screen and (max-width:640px) {
                        color: #fff;
                        top: -11px;
                        right: 0px;
                        font-size: 20px;

                    }
                }
            }

            .notification {
                width: 40px;
                height: 40px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50rem;
                cursor: pointer;
                position: relative;

                .icon {
                    font-size: 1.2rem;
                }

                .counts {
                    position: absolute;
                    background-color: red;
                    border-radius: 50%;
                    width: 1.2rem;
                    top: 0;
                    right: 5px;
                    color: white;
                    text-align: center;
                    font-size: 12px;
                    padding: 2px;
                }
            }

            .nav-popover {
                .imgBox {
                    height: 40px;
                    width: 40px;
                    cursor: pointer;
                    border-radius: 50rem;
                    overflow: hidden;

                    img {
                        object-fit: cover;
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.ant-popover {

    .popoverHeading {
        color: var(--primaryTextColor);

        .name {
            font-size: 1rem;
            font-weight: bold;
        }

        .skill {
            color: var(--primaryTextColor);
            font-size: .65rem;
        }
    }

    .antPopover {
        display: flex;
        flex-direction: column;
        gap: .8rem;

        .popoverItem {
            padding: .5rem;
            box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 2px;
        }
    }
}

.notifycarts {
    height: 18rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 5px;
    width: 100%;
    max-width: 350px;
    padding-right: 0.5rem;
    z-index: -1;




    .notifycart {
        display: flex;
        gap: 1rem;
        // background-color: rgb(227, 244, 252);
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;

        .notifycartImage {
            width: 2.5rem;
            height: 2.5rem;
            border-radius: 1rem;
        }

        .notifycontent {
            display: flex;
            flex-direction: column;


            .notifytitle {
                font-size: 13px;
                font-weight: bold;
            }

            .notifydatesource {
                display: flex;
                gap: 1rem;
                font-size: 12px;

                .notifysource {
                    text-transform: capitalize;
                }
            }
        }
    }
}