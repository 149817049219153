.ManageAccessMain {
  background: #ffffff;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 2rem;
  .avatar-uploader {
    margin-top: 2rem;
    @media screen and (max-width: 500px) {
      display: flex;
      justify-content: center;
    }
  }

  .head {
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .iconAccess {
      color: var(--dark);
    }

    .headingAccess {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      @media screen and (max-width: 800px) {
        font-size: 14px !important;
      }
    }
  }

  .inputMain {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 1rem;
    width: 100%;

    .inputFields {
      gap: 2rem;
      display: flex;
      width: 100%;
      @media screen and (max-width: 800px) {
        flex-direction: column;
      }

      .field {
        display: flex;
        width: 100%;
        display: flex;
        flex-direction: column;
        .lableName {
          padding: 0.5rem 3px;
          font-size: 14px;
          font-weight: 400;
        }

        .input {
          width: 100%;
          min-width: 10rem;
          height: 71px;
          padding: 12px !important;
          background: white;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
          border-radius: 9px;
          gap: 0.3rem;
          input:-webkit-autofill,
          input:-webkit-autofill:hover,
          input:-webkit-autofill:focus,
          input:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
          }
          @media screen and (max-width: 800px) {
            height: 40px !important;
            padding: 6px !important;
            font-size: 14px;
          }
          @media screen and (max-width: 500px) {
            font-size: 12px;
          }
          .icon {
            @media screen and (max-width: 500px) {
              display: none;
            }
          }
        }
        .textarea {
          padding: 1rem;
          width: 100%;
          min-width: 10rem;
          background: white;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
          border-radius: 9px;
          border: transparent;
        }
      }
    }
    .yellowGraBtn {
      width: 7rem;
      display: flex;
      align-self: flex-end;
      transition: ease-in-out 0.3s;
      &:hover {
        color: #282c35 !important;
      }
    }
  }
}
