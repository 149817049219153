.dashboardUsersContainer {
  padding-top: 1rem;
}

.yellowGraBtn {
  transition: ease-in-out .3s;

  &:hover {
    color: #282c35 !important;
  }
}