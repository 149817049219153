.allBlogsContainer {
  height: 100%;
  position: relative;

  //   padding: 1rem;
  .flexLineSpace {
    padding-top: 1rem;
    // padding: 0 30px 0 32px;

    @media only screen and (max-width:600px) {
      padding: .5rem 0 0 0;
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
    }

    .heading {
      @media screen and (max-width: 500px) {
        font-size: 18px;
      }
    }

    .dashboardBtn {
      width: 120px;

      @media screen and (max-width: 500px) {
        font-size: 13px;
        width: 100px;
      }
    }
  }

  .buttonandFilter {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;

    .filterMain {
      height: 40px;
      transition: ease-in-out 0.2s;

      &:hover {
        scale: 1.01;
      }
    }

    .ant-select-selector {
      border: transparent;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 20px 35px;
      text-align: center;
      border-radius: 20px;
      width: 10rem;
      color: var(--themeColor);
      background: linear-gradient(101.19deg, #143745 2.03%, #0a1c29 100%);

      @media screen and (max-width: 500px) {
        width: 7rem;
        padding: 20px 5px;
      }
    }

    .ant-select-selection-item {
      color: var(--themeColor);
    }
  }

  .BlogContainerMain {
    margin: 0.5rem;
    padding: 1.5rem;
    background-color: white;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.301);

    @media screen and (max-width: 500px) {
      padding: 5px;
    }

    .skeleton {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;
      height: 100vh;
      margin-top: 4rem;

      .skel {
        height: 20rem;
        width: 20rem;
        margin-bottom: 2rem;
      }
    }
  }

  .flexLineSpace {
    // margin: .5rem;
    padding-bottom: 1rem;
  }

  .blogsBox {
    padding: 2rem 0;
    gap: 3rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    grid-auto-rows: 450px;
    grid-auto-flow: dense;

    @media screen and (max-width: 500px) {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .blog {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 0.5rem;
      padding: 1rem;
      border-radius: 10px;
      box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
      width: 362px;
      margin: auto;

      @media screen and (max-width: 500px) {
        width: 230px;
        margin: inherit;
      }

      .blogImage {
        position: relative;

        img {
          width: 100%;
          height: 200px;
          border-radius: 10px;
          object-fit: cover;

          @media screen and (max-width: 500px) {
            height: 150px;
          }
        }

        .tag {
          position: absolute;
          top: 10px;
          right: 10px;
          width: fit-content;
          padding: 0.4rem 1rem;
          background: var(--themeColorGreenGradient);
          box-shadow: var(--themeBackGreenBoxShadow);
          border-radius: 2px;
          font-size: small;
          text-transform: uppercase;
        }
      }

      .details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .title {
          font-size: 15px;
          font-weight: 600;
          text-transform: capitalize;
          color: var(--primaryTextColor);
        }

        .content {
          height: 80px;
          text-align: justify;
          font-style: normal;
          font-weight: 400;
          line-height: 18px;
          display: flex;
          align-items: center;
          overflow: hidden;
          font-size: 12px;
          color: var(--primaryTextColor);

          @media screen and (max-width: 500px) {
            height: 110px;
          }
        }
      }

      .blogButtons {
        width: 100%;
        padding-top: 0.5rem;
        padding-bottom: 1rem;
        display: flex;
        gap: 1rem;

        .CommonBtnBlog {
          font-size: 5px;
          height: 15px;
        }

        .dangerBtn {
          background-color: red !important;
        }
      }
    }
  }
}

.customheadingblog {
  margin-left: 2%;
}