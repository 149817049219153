@import url('https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap');


button {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button {
    border-radius: 0;
}

button {
    text-transform: none;
}

button {
    -webkit-appearance: button;
    cursor: pointer;
}

button {
    border-width: 0;
}

button {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn {
    outline: none;
    position: relative;
    display: inline-flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    user-select: none;
    touch-action: manipulation;
    color: rgba(0, 0, 0, 0.88);
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn {
    font-size: 14px;
    line-height: 1.5714285714285714;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn-default {
    background: #ffffff;
    border-color: #d9d9d9;
    color: rgba(0, 0, 0, 0.88);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
}

.yellowGraBtn {
    transition: ease-in-out 0.3s;
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 14px;
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn {
    outline: none;
    position: relative;
    display: inline-flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    background-image: none;
    background: transparent;
    border: 1px solid transparent;
    cursor: pointer;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    -webkit-user-select: none;
    user-select: none;
    touch-action: manipulation;
    color: rgba(0, 0, 0, 0.88);
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn {
    font-size: 14px;
    line-height: 1.5714285714;
    height: 32px;
    padding: 4px 15px;
    border-radius: 6px;
}

.yellowGraBtn {
    max-height: 40px;
    height: 40px;
    width: 100%;
    background: var(--themeColor);
    padding: 20px 35px;
    color: var(--light);
    border-radius: var(--radius);
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

.yellowGraBtn {
    background: linear-gradient(94.04deg, #ffc01e 22.18%, #ffdb7e 100%);
    box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.1);
    border-radius: 76px;
}

.yellowGraBtn {
    width: 7rem;
    display: flex;
    align-self: flex-end;
}

.yellowGraBtn {
    width: 7rem;
    display: flex;
    align-self: flex-end;
    transition: ease-in-out 0.3s;
}

.yellowGraBtn {
    width: 7rem;
    display: flex;
    align-self: flex-end;
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn:before,
:where(.css-dev-only-do-not-override-3rel02).ant-btn:after {
    box-sizing: border-box;
}

:-webkit-scrollbar {
    width: 5px;
}

:-webkit-scrollbar-thumb {
    margin-top: 1rem;
    border-radius: 10px !important;
    background: #d1d1d1;
}

:-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: transparent;
}

.yellowGraBtn:hover {
    color: #282c35 !important;
}

.ant-btn-default:not(:disabled):hover {
    color: #fff;
    border-color: var(--themeColorGreen);
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #4096ff;
    border-color: #4096ff;
    background: #ffffff;
}

.ManageAccessMain .inputMain .yellowGraBtn:hover {
    color: #282c35 !important;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: #ffc01e;
    background-color: #ffc01e;
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    color: #fff;
    border-color: #ffc01e;
}

:where(.css-dev-only-do-not-override-3rel02).ant-btn>span {
    display: inline-block;
}