.donutchart_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    height: 100%;

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        color: black;
        text-align: center;
    }

    .chart {
        height: 90%;
    }

    .status_box {
        display: flex;
        gap: 8px;

        .status {
            display: flex;
            gap: 4px;
            align-items: center;

            .dot {
                width: 8px;
                height: 8px;
                background-color: #259E55;
                border-radius: 50%;
            }

            .dot_title {
                font-style: normal;
                font-weight: 500;
                font-size: 10px;
                line-height: 110%;
                color: #606C80;

            }
        }

    }
}