.ProfileMain {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 0 32px 0 32px;

    @media only screen and (max-width:600px) {
        padding: 0;
    }

    .heading {
        text-transform: uppercase;
    }
}