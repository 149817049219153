.ant-modal {
    max-width: 550px !important;
    min-height: 300px;

    @media only screen and (max-width:600px) {
        max-width: 350px !important;
    }

    .messagePopUp {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 15px;
        padding: 1rem;
        position: relative;

        .successLogo {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width:600px) {
                width: 70px;
                height: 70px;
            }

            .icon {
                font-size: 30px;
            }
        }

        .warning {
            box-shadow: 0px 5px 15px 10px #FFE46F4D;
            background: #FCD117;
        }

        .danger {
            box-shadow: 0px 5px 15px 10px #e74d3c4f;
            background: linear-gradient(275.12deg, #b40901 5.38%, #fb4b4d 94%);
            color: white;
        }

        .success {
            box-shadow: 0px 5px 15px 10px #FFE46F4D;
            background: #FCD117;
        }

        .heading {
            font-size: 25px;
            font-weight: 600;
            letter-spacing: 0.10000000149011612px;
            text-align: center;

        }

        .para {
            font-size: 15px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.10000000149011612px;
            text-align: center;

        }
    }
}