.participants-container {
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0px 0px 15px 2px #00000040;

  .ant-card-head {
    background-color: #EBEBEB;
    padding: 1.5rem;
    font-size: 1.1rem;

    .ant-card-head-title {
      display: flex;
      align-items: center;
      gap: .5rem;
    }
  }

  .ant-card-body {

    .participantsList {
      display: flex;
      flex-direction: column;
      gap: .5rem;
    }

    .participant {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: white;
      box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.25);
      border-radius: 5rem;
      padding: .5rem;

      .avater {
        height: 40px;
        width: 40px;
        border-radius: 50rem;
        overflow: hidden;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        color: white;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .name {
        display: flex;
        flex-direction: column;
        font-size: .8rem;

        .title {
          color: #5E5E5E;
          font-weight: bold;
        }

        .email {
          font-size: .7rem;
        }

      }

      .icons {
        .icon {
          border-radius: 50rem;
          box-shadow: 0px 0px 7px 1px rgba(0, 0, 0, 0.2);
          padding: .3rem;
          cursor: pointer;
          height: 30px;
          width: 30px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 1rem;
          color: #5E5E5E;
        }
      }

    }
  }

}