.dashboardAllUsersContainer {


  .table {
    overflow: hidden !important;

    .tag {
      left: -16px !important;
    }

    .tag2 {
      left: -11px;
    }

    .centered-column {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    .start-column {
      width: 35%;
    }

    .actionBox {
      .actionBtn {
        width: unset;
        height: unset;
        font-size: .6rem;
      }
    }

  }
}

.reviewModel {
  .ant-modal-content {
    padding: 0;
    overflow: hidden;
    border-radius: .7rem;
  }
}

.ant-table-content {
  overflow: hidden !important;
}