.main {
    .section {
        margin: 2rem 0;
        padding: 3rem 3rem;
        background-color: #fff;
        border-radius: 10px;
        border-radius: .8rem;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        gap: 1rem;

        .heading {
            // margin: auto;
            display: flex;
            padding-bottom: 1rem;
            font-weight: 600;
            font-size: 1.7rem;

            .icon {
                font-size: 3rem;
                cursor: pointer;
            }
        }

        .detail-section {

            padding: 1rem;
            display: grid;
            gap: 2rem 1rem;

            .detail-row {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                .detail-col {
                    width: 100%;

                    .title p {
                        font-family: 'Poppins';
                        font-size: 15px;
                        font-weight: 400;
                        line-height: 23px;
                        letter-spacing: 0em;
                        text-align: left;
                        margin-bottom: 0.7rem;
                    }

                    .detail {
                        :where(.css-dev-only-do-not-override-1k979oh).ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
                            // width: 100%;
                            // height: 100%;
                            // padding: 25px 11px;
                            // box-shadow: 0px 1px 5px 1px rgb(0 0 0 / 20%);
                            // border: none;
                            height: 71px;
                            padding: 0px 25px;
                            padding-top: 0px;
                            background: #ffffff;
                            box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                            border-radius: 9px;
                        }

                        p {
                            font-family: 'Poppins';
                            font-size: 17px;
                            font-weight: 500;
                            line-height: 27px;
                            letter-spacing: 0em;
                            text-align: left;
                        }
                    }

                    .submit-button {
                        text-align: center;
                    }
                }
            }

        }

    }
}

.inputselect {
    width: 100%;
    min-width: 40rem;
    height: 71px;
    padding: 12px !important;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    border: 0.7px solid #c9c9c9;
    gap: 0.3rem;
    display: flex;
    align-items: center;
}

* {
    padding: 0;
    margin: 0;
    border: 0;
    outline: none;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
    scroll-behavior: auto;
    font-family: "Poppins", sans-serif;
    -webkit-user-select: none;
    user-select: none;
}

.inputselect {
    width: 100%;
    min-width: 10rem;
    height: 71px;
    padding: 12px !important;
    background: white;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
    gap: 0.3rem;
    display: flex;
    align-items: center;
}

:-webkit-scrollbar {
    width: 5px;
}

:-webkit-scrollbar-thumb {
    margin-top: 1rem;
    border-radius: 10px !important;
    background: #d1d1d1;
}

:-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: transparent;
}

.selecticon {
    padding-left: 0.3rem;
}

:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"],
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 14px;
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple {
    font-size: 14px;
}

.selector {
    border: transparent;
    box-shadow: none;
    display: flex;
    width: 100%;
}

.ant-select-multiple {
    overflow-y: scroll;
    max-height: 28px !important;
}

.selector {
    border: transparent;
    box-shadow: none;
    display: flex;
    width: 100%;
}

:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"]:before,
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"]::before,
:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"]:after,
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"]::after {
    box-sizing: border-box;
}

svg:not(:root) {
    overflow: hidden;
}

.ant-select-selector {
    width: 100%;
    height: 42px;
    padding: 5px 20px;
    box-shadow: none;
    border-radius: var(--radius);
    border: 1px solid var(--border);
}

:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"] [class^="ant-select"],
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] [class^="ant-select"] {
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selector {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    height: 100%;
    padding-inline: 3px;
    padding-block: 1px;
    border-radius: 6px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-borderless .ant-select-selector {
    background: transparent;
    border-color: transparent;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select-show-search:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selector {
    cursor: text;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple.ant-select-show-arrow .ant-select-selector {
    padding-inline-end: 24px;
}

.ant-select-show-search:where(.css-dev-only-do-not-override-3rel02).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    cursor: text;
}

.ant-select-selector {
    border: none;
    display: flex;
    justify-content: center;
    font-size: 1rem;
    align-items: center;
    height: -webkit-fill-available;
    scroll-behavior: smooth;
    padding: 0 0.3rem;
}

:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"] [class^="ant-select"]::before,
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] [class^="ant-select"]::before,
:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"] [class*=" ant-select"]::before,
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] [class*=" ant-select"]::before,
:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"] [class^="ant-select"]::after,
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] [class^="ant-select"]::after,
:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"] [class*=" ant-select"]::after,
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] [class*=" ant-select"]::after {
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selector::after {
    display: inline-block;
    width: 0;
    margin: 2px 0;
    line-height: 24px;
    visibility: hidden;
    content: "\a0";
}

.ant-select-selector:hover {
    border: none !important;
}

.ant-select-selector:hover {
    border-color: var(--themeColor) !important;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select .ant-select-arrow {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.25);
    font-style: normal;
    line-height: 1;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    top: 50%;
    inset-inline-start: auto;
    inset-inline-end: 11px;
    height: 12px;
    margin-top: -6px;
    font-size: 12px;
    pointer-events: none;
    transition: opacity 0.3s ease;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow {
    position: relative;
    display: flex;
    flex: auto;
    flex-wrap: wrap;
    max-width: 100%;
}

.anticon {
    display: inline-flex;
    align-items: center;
    color: inherit;
    font-style: normal;
    line-height: 0;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"] [class*=" ant-select"],
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] [class*=" ant-select"] {
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select .ant-select-arrow>* {
    line-height: 1;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select .ant-select-arrow .anticon {
    vertical-align: top;
    transition: transform 0.3s;
}

.anticon:before {
    display: none;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow-item {
    flex: none;
    align-self: center;
    max-width: 100%;
    display: inline-flex;
}

:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"] [class^="ant-select"],
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] [class^="ant-select"],
:where(.css-dev-only-do-not-override-3rel02)[class^="ant-select"] [class*=" ant-select"],
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-select"] [class*=" ant-select"] {
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow-item-suffix {
    height: 100%;
}

.anticon>* {
    line-height: 1;
}

.anticon svg {
    display: inline-block;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select .ant-select-arrow svg {
    display: inline-block;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select .ant-select-arrow .anticon>svg {
    vertical-align: top;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select .ant-select-selection-item {
    flex: 1;
    font-weight: normal;
    position: relative;
    user-select: none;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-item {
    height: 24px;
    line-height: 22px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
    display: flex;
    align-self: center;
    flex: none;
    box-sizing: border-box;
    max-width: 100%;
    margin-block: 2px;
    border-radius: 4px;
    cursor: default;
    transition: font-size 0.3s, line-height 0.3s, height 0.3s;
    margin-inline-end: 4px;
    padding-inline-start: 8px;
    padding-inline-end: 4px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-borderless.ant-select-multiple .ant-select-selection-item {
    background: rgba(0, 0, 0, 0.06);
    border: 1px solid transparent;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-search {
    display: inline-flex;
    position: relative;
    max-width: 100%;
    margin-inline-start: 8px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow-item+.ant-select-selection-overflow-item .ant-select-selection-search {
    margin-inline-start: 0;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-content {
    display: inline-block;
    margin-inline-end: 4px;
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove {
    display: inline-flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.45);
    font-style: normal;
    line-height: inherit;
    text-align: center;
    text-transform: none;
    vertical-align: -0.125em;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: bold;
    font-size: 10px;
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item-remove:hover {
    color: rgba(0, 0, 0, 0.88);
}

input {
    color: inherit;
    font: inherit;
    margin: 0;
}

input {
    line-height: normal;
}

input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input {
    outline: none;
}

input {
    accent-color: var(--themeColorGreen);
}

input[type="search"] {
    -webkit-appearance: textfield;
    -webkit-box-sizing: content-box;
    font-family: inherit;
    font-size: 100%;
}

input[type="search"] {
    padding: 9px 10px 9px 32px;
    width: 170px;
    background: #ededed url(https://static.tumblr.com/ftv85bp/MIXmud4tx/search-icon.png) no-repeat 9px center;
    border: 0px;
    border-radius: 0px;
    transition: all 0.5s;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-search-input {
    height: 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 24px;
    transition: all 0.3s;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-search-input {
    width: 100%;
    min-width: 4.1px;
}

input[type="search"]:focus {
    width: 220px;
    background-color: #fff;
    border-color: #fcd117;
    // box-shadow: 0 0 5px rgba(109, 207, 246, 0.5);
}

:where(.css-dev-only-do-not-override-3rel02).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: pointer;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    margin: 0;
    padding: 0;
    background: transparent;
    border: none;
    outline: none;
    appearance: none;
    font-family: inherit;
}

.ant-select-show-search:where(.css-dev-only-do-not-override-3rel02).ant-select:not(.ant-select-customize-input) .ant-select-selector input {
    cursor: auto;
    color: inherit;
    height: 100%;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-search-mirror {
    height: 24px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 24px;
    transition: all 0.3s;
}

:where(.css-dev-only-do-not-override-3rel02).ant-select-multiple .ant-select-selection-search-mirror {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    inset-inline-end: auto;
    z-index: 999;
    white-space: pre;
    visibility: hidden;
}


/* These were inline style tags. Uses id+class to override almost everything */
#style-g4JSh.style-g4JSh {
    width: 0px;
    height: 0px;
    position: absolute;
    overflow: hidden;
    opacity: 0;
}

#style-CPRZa.style-CPRZa {
    opacity: 1;
}

#style-ySBnO.style-ySBnO {
    user-select: none;
}

#style-MQrnD.style-MQrnD {
    opacity: 1;
}

#style-veQwU.style-veQwU {
    width: 4px;
}

#style-kMhGM.style-kMhGM {
    user-select: none;
}



.Inputfield {
    width: 100%;
    display: flex;
    gap: 1rem;
}

:-webkit-scrollbar {
    width: 5px;
}

:-webkit-scrollbar-thumb {
    margin-top: 1rem;
    border-radius: 10px !important;
    background: #d1d1d1;
}

:-webkit-scrollbar-track {
    border-radius: 10px !important;
    background-color: transparent;
}

.field {
    width: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
}

.lableName {
    padding: 0.5rem 3px;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark);
}

img {
    border: 0;
}

img {
    vertical-align: middle;
}

.degreeImage2 {
    position: absolute;
    top: 57%;
    z-index: 1;
    left: 19px;
}

input {
    color: inherit;
    font: inherit;
    margin: 0;
}

input {
    line-height: normal;
}

input {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit;
}

input {
    outline: none;
}

input {
    accent-color: var(--themeColorGreen);
}

:where(.css-dev-only-do-not-override-3rel02)[class^="ant-input"],
:where(.css-dev-only-do-not-override-3rel02)[class*=" ant-input"] {
    box-sizing: border-box;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    border-radius: 6px;
    transition: all 0.2s;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input-lg {
    padding: 7px 11px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 8px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input-outlined {
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
}

.ant-input {
    border-color: var(--border);
}

:where(.css-dev-only-do-not-override-3rel02).ant-input:placeholder-shown {
    text-overflow: ellipsis;
}

.InsInput {
    width: 100%;
    min-width: 10rem;
    height: 71px;
    padding: 12px 52px;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input-affix-wrapper-lg {
    padding: 7px 11px;
    font-size: 16px;
    line-height: 1.5;
    border-radius: 8px;
}

.ant-input-affix-wrapper {
    padding: 9px 20px !important;
    border-radius: var(--radius);
    border: 1px solid var(--border);
}

.ant-input-affix-wrapper-lg {
    border: transparent;
}

.eduInput {
    width: 100%;
    min-width: 10rem;
    height: 71px;
    padding: 12px 50px;
    background: #ffffff;
    border: 1px solid #f3f3f3;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
}

:where(.css-dev-only-do-not-override-3rel02).ant-input-affix-wrapper:before {
    display: inline-block;
    width: 0;
    visibility: hidden;
    content: "\a0";
}

:where(.css-dev-only-do-not-override-3rel02).ant-input-outlined:hover {
    border-color: #4096ff;
    background-color: #ffffff;
}

.ant-input-affix-wrapper:hover {
    border-color: var(--themeColor) !important;
}