.EducationHistoryMainConatiner {
  .addEductionContainer {
    background: #ffffff;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 2rem;
  }

  .allEducationContainer {}

  .EducationHistoryHeading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .eduDiv {
      display: flex;
    }

    .heading {
      text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 23px;
      line-height: 30px;
      padding-left: 13px;
    }

    .AddBtn {
      padding: 1rem;
      align-self: center;
      width: 8rem;
      font-weight: 700;
      font-size: 15px;
    }
  }

  .InputFields {
    display: flex;
    flex-direction: column;
    padding-top: 2rem;
    gap: 1rem;
    width: 100%;

    .Inputfield {
      width: 100%;
      display: flex;
      gap: 1rem;

      .field {
        width: 100%;
        display: flex;
        position: relative;
        flex-direction: column;
      }

      .field3 {
        margin-top: 1%;
      }

      .eduInput {
        width: 100%;
        min-width: 10rem;
        height: 71px;
        padding: 12px 50px;
        background: white;
        border: 1px solid #f3f3f3;
        background: #ffffff;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 9px;

        input {
          background-color: inherit;
        }
      }

      .InsInput {
        width: 100%;
        min-width: 10rem;
        height: 71px;
        padding: 12px 52px;
        background: white;
        border: 1px solid #f3f3f3;
        background: #ffffff;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 9px;

        input {
          background-color: inherit;
        }
      }

      .educationField {
        display: flex;
        justify-content: space-between;
        align-items: center;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        input {
          background-color: inherit;
        }

        .TypeHeading {
          font-family: "Poppins";
          font-style: normal;
          font-weight: 500;
          font-size: 15.0973px;
          line-height: 20px;
          display: flex;
          align-items: center;
          color: #323232;
        }
      }

      .registerSelector {
        .ant-select-selector {
          height: 71px;
          padding: 0px 50px;
          padding-top: 0px;
          background: #ffffff;
          box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
          border-radius: 9px;
        }

        .ant-select-selection-placeholder {
          padding-left: 20px;
        }

      }

      .degreeImage2 {
        position: absolute;
        top: 57%;
        z-index: 1;
        left: 19px;
      }

      .ant-picker .ant-picker-input>input:placeholder-shown {
        padding-left: 6px;
      }

      .degreeImage {
        position: absolute;
        top: 38%;
        z-index: 1;
        left: 24px;
      }

      .progressInput {
        width: 100%;
        display: flex;
        align-items: center;
        min-width: 10rem;
        height: 71px;
        padding: 12px 22px;
        background: white;
        border: 1px solid #f3f3f3;
        background: #ffffff;
        box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
        border-radius: 9px;

        .progressHeading {
          font-size: inherit;
          font-weight: inherit;
          color: inherit;
          padding-left: 8px;
        }

        .progressRadio {
          position: absolute;
          right: 0;
          bottom: 22px;
        }
      }
    }

    .saveButton {
      display: flex;
      justify-content: end;

      .btn {
        width: 20px;
        padding: 1rem;
        align-self: center;
        width: 8rem;
        font-weight: 700;
        font-size: 15px;
      }
    }
  }

  .attachment {
    width: 53%;
    min-width: 10rem;
    height: 71px;
    padding: 12px 38px;
    background: white;
    border: 1px solid #f3f3f3;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 9px;
  }



  .uploadBtn {
    display: flex;
    align-items: center;
    width: 53%;
    min-width: 10rem;
    height: 71px;
    padding: 12px 20px;
    background: white;
    border: 1px solid #f3f3f3;
    background: #ffffff;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 9px;

    .upload {
      outline: none;
      border: none;
      box-shadow: none;
    }

    .ant-upload {
      height: 40px !important;
      width: 60px !important;
    }

    .upload:hover {
      transform: scale(1.1);
    }

    .icon {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      flex-grow: 1; // Allow the icon to take up available space
    }

    .iconEye {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 40px;
      height: 40px;

      &:hover {
        transform: scale(1.1);
      }
    }
  }

}

.TableContainer {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding: 2rem;

}

.errorclass {
  position: absolute;
  bottom: -20px;
  /* Position the error message below the input field */
  left: 0;
  width: 100%;
}

.error {
  color: red;
  font-size: 12px;
  margin: 0;
}