.meetingContainer {
    .flexLineSpace {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        margin-top: 40px;
    }

    .heading {
        font-size: 24px;
        font-weight: bold;
    }

    .table {
        padding-bottom: -5px;
        /* Reduce the padding-bottom value */
    }

    .custom-select {
        width: 150px !important;
        height: 43px !important;
        margin-right: 40px !important;

        &:hover {
            scale: 1.01;
        }

        .ant-select-selector {
            background-color: #12263f !important;
            color: #ffd700 !important;
            border: none !important;
            border-radius: 20px !important;
            padding: 8px 16px !important;
            display: flex;
            align-items: center;
            justify-content: center !important;
            /* Center the content */
            text-align: center;
            /* Center the text */
        }

        .ant-select-arrow {
            color: #ffd700 !important;
        }

        .ant-select-selection-item {
            color: #ffd700 !important;
            margin: auto;
            margin-bottom: 10px;
            /* Center the selection item */
        }
    }
}

.meetingheading {
    margin-left: 2.5%;
}