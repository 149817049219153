.AddEventMain {
    .heading {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
            font-size: 2.5rem;
        }
    }

    .AddBodyMain {
        margin: 2rem 0;
        padding: 1.5rem;
        background-color: white;
        border-radius: .8rem;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.301);
        gap: 1rem;
        display: flex;
        justify-content: space-evenly !important;
        align-items: center;

        @media screen and (max-width:800px) {
            flex-direction: column-reverse;
        }

        .Events {
            display: flex;
            flex-direction: column;
            width: 60%;
            padding-bottom: 50px;


            .imgContainer {
                .ant-upload {
                    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
                }

            }



            @media screen and (max-width:800px) {
                width: 100%;
            }

            .lableName {
                margin-top: 1rem;
            }

            .eventInput {

                width: 100%;
                min-width: 10rem;
                height: 52px;
                padding: 12px 30px !important;
                background: white;
                // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.12);

                border-radius: 5px;
                border: transparent;
                gap: 0.3rem;

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                }

                input {
                    background-color: inherit;

                }

                &:hover {
                    border-color: var(--themeColorGreen);
                }
            }

            .eventDescription {
                width: 100%;
                min-width: 10rem;
                min-height: 228px;
                padding: 12px 30px !important;
                background: white;
                // box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.12);

                border-radius: 5px;
                border: transparent;
                gap: 0.3rem;
                text-align: start;

                input:-webkit-autofill,
                input:-webkit-autofill:hover,
                input:-webkit-autofill:focus,
                input:-webkit-autofill:active {
                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                }

                input {
                    background-color: inherit;

                }

                &:hover {
                    border-color: var(--themeColorGreen);
                }

            }

            .BtnReadMore {
                display: flex;
                justify-content: flex-end;
                margin-top: 30px;

                .yellowGraBtn {
                    width: 130px;
                }

                .readMoreBtn {
                    display: flex;
                    justify-content: flex-end;
                    background-color: var(--themeColor);

                }

                .ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
                    color: var(--dark);
                }
            }

        }

        .CalenderMain {
            width: 100%;
            max-width: 375px;


            .css-qa7bje-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
                background-color: var(--themeColor) !important;

            }

            .css-11a8txn-MuiStack-root>:not(style):not(style) {
                margin: auto;
            }

            .css-1rv8zyt-MuiStack-root {
                box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.12);
                border-radius: 5px;
            }

            .MuiDateCalendar-root {
                @media screen and (max-width:500px) {
                    width: inherit !important;
                }
            }

            .MuiPickersDay-today {
                @media screen and (max-width:500px) {
                    border: none;
                }
            }

            .MuiPickersDay-today {
                @media screen and (max-width:500px) {
                    color: red;
                }
            }
        }
    }
}