.ScheduleMain {
    background: #FFFFFF;
    box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    padding: 2rem;

    .head {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .iconSchedule {
            color: var(--dark);
        }

        .headingInfo {
            text-transform: uppercase;
            font-style: normal;
            font-weight: 500;
            font-size: 23px;
            line-height: 30px;
        }

    }

    .inputMain {
        display: flex;
        flex-direction: column;
        padding-top: 2rem;
        gap: 1rem;
        width: 100%;

        .inputFields {
            gap: 1rem;
            display: flex;
            width: 100%;

            

            .rateField {
                max-width: 100%;
                width: 100%;
                height: 71px;
                padding: 12px !important;
                box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                border-radius: 9px;
                position: relative;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }

            .rate {
                display: flex;
                align-items: center;
                padding-left: 0.5rem;
            }



            .field {
                display: flex;
                width: 100%;
                flex-direction: column;

                .input {
                    width: 100%;
                    min-width: 10rem;
                    height: 71px;
                    padding: 12px !important;
                    background: white;
                    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
                    border-radius: 9px;
                    gap: 0.3rem;

                    input:-webkit-autofill,
                    input:-webkit-autofill:hover,
                    input:-webkit-autofill:focus,
                    input:-webkit-autofill:active {
                        -webkit-box-shadow: 0 0 0 30px white inset !important;
                    }

                    .iconInfo {
                        color: var(--dark);
                    }

                    .ant-select-selector {
                        border: none;
                        display: flex;
                        justify-content: center;
                        font-size: 1rem;
                        display: flex;
                        align-items: center;
                        height: -webkit-fill-available;
                        scroll-behavior: smooth;
                    }
                }
            }
        }

        .yellowGraBtn {
            width: 7rem;
            display: flex;
            align-self: flex-end;
        }
    }
}