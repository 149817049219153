.dashboardHomeContainers {
  width: 100%;
  position: relative;
  padding: 25px 32px 25px 30px;

  .reportBox {
    display: flex;
    justify-content: space-between;
    gap: 20px; // Add some gap between the two sections
    margin-top: 30px;
    ;

    .assignmentSummary,
    .weeklyWiseMeetings {
      background: white;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      width: 48%;

      .subHeading {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 20px;
        color: #757575; // Light gray color for the subheading text
      }
    }

    .assignmentSummary {
      .progressItem {
        margin-bottom: 20px; // Adjust the spacing between progress items

        .progressText {
          font-size: 14px;
          color: #757575; // Light gray color for the text
          margin-bottom: 5px;
        }

        .ant-progress {
          margin-bottom: 10px;

          .ant-progress-bg {
            background-color: #FFB800; // Change the progress bar color to yellow
          }

          .ant-progress-outer {
            background-color: #E0E0E0; // Change the background color of the progress bar track
          }
        }

        .progressNumbers {
          font-size: 14px;
          color: #757575; // Light gray color for the numbers
          margin-bottom: 5px; // Add margin-bottom to separate from progress bar
        }
      }
    }

    .weeklyWiseMeetings {
      .chart {
        height: 100%;
        // Add styles for your chart if needed
      }
    }
  }
}

:where(.css-dev-only-do-not-override-3rel02).ant-progress .ant-progress-bg::after {
  background-color: green; // Change the progress bar color to yellow
}