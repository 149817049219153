.dashboardAllUsersContainer {
  .table {
    .tag {
      left: -16px !important;
    }
    .tag2 {
      left: -11px;
    }

    .centered-column {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
    .start-column {
      width: 35%;
    }
  }
}
