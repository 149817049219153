.allEventsBox {
    margin-top: 1rem;

    .ViewBodyMain {
        margin: 2rem 0;
        padding: 1.5rem;
        background-color: white;
        border-radius: .8rem;
        min-height: 80vh;
        max-width: 100%;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.301);
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        gap: 2rem;

        @media screen and (max-width:500px) {
            gap: 2rem;
        }

        .skeleton {
            width: 100%;
            min-height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            gap: 85px;
            padding: 1rem;

            .skel {
                width: 100%;
                max-width: 320px;
                min-height: 350px;
            }
        }


        .blog {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 0.5rem;
            padding: 1rem;
            border-radius: 10px;
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
            width: 362px;
            margin: auto;

            @media screen and (max-width: 500px) {
                width: 230px;
            }

            .blogImage {
                .img {
                    position: relative;
                    width: 100%;
                    height: 200px;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        border-radius: 10px;
                    }

                    .badge {
                        position: absolute;
                        top: -8px;
                        left: 0;
                        background: url('../../../../../../Assets/Images/badge.png');
                        width: 50px;
                        height: 72px;
                        padding-top: 4px;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        color: #fff;
                        font-size: 18px;

                        @media screen and (max-width:"425px") {
                            font-size: 17px;
                        }
                    }
                }
            }

            .details {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;

                .title {
                    font-size: 15px;
                    font-weight: 600;
                    text-transform: capitalize;
                    color: var(--primaryTextColor);
                }

                .content {
                    height: 80px;
                    text-align: justify;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 18px;
                    display: flex;
                    align-items: center;
                    overflow: hidden;
                    font-size: 12px;
                    color: var(--primaryTextColor);

                    @media screen and (max-width: 500px) {
                        height: 110px;
                    }
                }
            }

            .blogButtons {
                width: 100%;
                padding-top: 0.5rem;
                padding-bottom: 1rem;
                display: flex;
                gap: 1rem;

                .CommonBtnBlog {
                    font-size: 5px;
                    height: 15px;
                }

                .dangerBtn {
                    background-color: red !important;
                }
            }
        }
    }
}

.customheadingevents {
    margin-left: 2%;
}