.chat-app {
    max-width: 400px;
    margin: 0 auto;
    overflow: hidden;
    min-height: 200px;

    .noMessage {
        text-align: center;
    }
}


.message {
    clear: both;
    margin: 10px 0;
}

.message-content {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 10px;
    max-width: 70%;
    word-wrap: break-word;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.sender .message-content {
    float: right;
    background-color: #FCD117;
    margin-bottom: 24px;
    margin-top: 24px;
}

.receiver .message-content {
    float: left;
    background-color: #ECE5DD;
    margin-bottom: 2px;
}

.chat-header {
    background-color: #128c7e;
    color: #fff;
    padding: 10px;
    text-align: center;
    font-weight: bold;
}

.input-box {
    display: flex;
    background-color: #fff;
    padding: 10px;
    border-top: 1px solid #e5e5e5;
}

// .sender {
//     float: right;
//     background-color: #FCD117;
//     margin-bottom: 24px;
//     margin-top: 24px;
//     padding: 10px;
//     margin-bottom: 5px;
//     border-radius: 10px;
//     max-width: 70%;
//     word-wrap: break-word;
//     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
// }

// .receiver {
//     float: left;
//     background-color: #ECE5DD;
//     margin-bottom: 2px;
//     padding: 10px;
//     margin-bottom: 5px;
//     border-radius: 10px;
//     max-width: 70%;
//     word-wrap: break-word;
//     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
// }


.input-box input {
    flex: 1;
    margin-right: 10px;
}

.input-box button {
    width: 40px;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-btn.ant-btn-icon-only {
    width: 54px;
    border-radius: 12px;
    background-color: #FCD117;
}