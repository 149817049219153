.cardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  color: var(--primaryTextColor);

  @media screen and (max-width: 1450px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .hoverEffect {
    transition: 0.7s;

    &:hover {
      transform: scale(1.02);
    }
  }

  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 1.5rem;
    background-color: white;
    padding: 1.5rem 2.5rem;
    // box-shadow: 0px 5px 5px 2px rgba(15, 15, 15, 0.1);
    box-shadow: 3px 3px 8px 3px #0f0f0f33;
    border-radius: 15px;
    cursor: pointer;
    min-height: 20vh;

    .iconBox {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .icon {
        font-size: 3rem;
      }

      .counts {
        display: flex;
        gap: 0.2rem;
        font-size: 0.9rem;
        justify-content: center;
        align-items: center;
        background: var(--themeColorGreenGradient);
        padding: 0.3rem 0.5rem;
        border-radius: 1rem;
        color: white;
        box-shadow: 0px 0px 4px 2px rgba(53, 217, 102, 0.3);
      }

      .countDanger {
        box-shadow: 0px 0px 4px 1px rgba(190, 47, 93, 0.3);
      }
    }

    .detials {
      display: flex;
      flex-direction: column;
      gap: 0.3rem;

      .value {
        font-size: 1.5rem;
        font-weight: 700;

      }

      .title {
        color: gray;
        font-size: medium;
      }
    }
  }
}


.ant-skeleton-input {
  width: 25px !important;
  min-width: 20px !important;
}

.special {
  width: 300px;
}