.ant-layout,
.ant-layout-footer,
.ant-layout-sider {
    background: none;
}

:where(.css-dev-only-do-not-override-14wwjjs).ant-layout .ant-layout-sider-light {
    background: none;
}

.myVideoContainer {
    .myCallingContainer {
        display: flex;
        height: 100%;
        padding: 50px 40px;

        .myClientContainer {
            width: 70%;
            display: flex;
            justify-content: center;

            .myClient-box {
                display: block;
                width: 100%;
                text-align: center;
                height: 100%;
                padding: 30px 10px;
                border-radius: 15px;
                border: 1px solid #ededed;
                box-shadow: rgb(100 100 111 / 11%) 0px 7px 29px 0px;

                video {
                    height: 800px;
                    margin-bottom: 10px;
                }

                .myAvatar img {
                    width: 450px;
                    height: 400px;
                    border-radius: 10px;
                }

                .myUser-name {
                    font-size: 20px;
                    padding: 10px 0;
                }

                .myMic-icon {
                    button.myUsericonButton {
                        height: 40px;
                        width: 80px;
                        margin: auto;
                        border-radius: 15px;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
                    }
                }
            }
        }

        .myUserContainer {
            width: 30%;
            display: flex;
            justify-content: center;
            height: 50%;
            margin-left: 3%;

            .myUser-box {
                display: block;
                width: 100%;
                text-align: center;
                height: 100%;
                padding: 30px 10px;
                border-radius: 15px;
                border: 1px solid #ededed;
                box-shadow: rgb(100 100 111 / 11%) 0px 7px 29px 0px;

                video {
                    height: 435px;
                    margin-bottom: 10px;
                }

                .myAvatar img {
                    width: 200px;
                    height: 200px;
                    border-radius: 10px;
                }

                .myUser-name {
                    font-size: 20px;
                    padding: 10px 0;
                }

                .myVideo-icon {
                    button.myUsericonButton {
                        height: 40px;
                        width: 80px;
                        margin: auto 10px;
                        border-radius: 15px;
                        box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px;
                    }
                }
            }
        }
    }

    .myActionBar {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 0px;
        width: 100%;
        margin-top: 5%;

        .myActionButtons {
            display: flex;
            justify-content: center;
            gap: 30px;

            button {
                width: 80px;
                height: 50px;
                box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 4px;

                &:hover {
                    img {
                        color: #fff;
                        filter: brightness(10);
                    }
                }
            }
        }
    }
}