.dashboardHomeContainer {
  width: 100%;
  position: relative;
  padding: 0 32px 0 30px;


  @media only screen and (max-width:600px) {
    padding: 0 0rem;
  }

  .box {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  .longChart {
    margin: 1rem;

    .buttons {
      display: flex;
      justify-content: end;

      button {
        padding: 0.7rem 2rem;
        border-radius: 20px;
        margin: 0.7rem;
        color: white;
        font-size: 14px;
        background: linear-gradient(94.04deg, #ffc01e 40.18%, #ffdb7e 100%);
        transition: 0.3s ease-out;

        &:hover {
          background: var(--themeColorDark);
          transform: translateY(-7px);
          transition: 0.5s ease-in-out;
          color: #ffc01e;
        }
      }

      .active {
        background: var(--themeColorDark);
        transform: translateY(-7px);
        transition: 0.5s ease-in-out;
        color: #ffc01e;
      }
    }

    .chart {
      height: 30rem;
    }
  }

  .reportBox {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 3rem;

    @media screen and (max-width: 1450px) {
      .datePicker {
        width: 250px !important;
      }
    }

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }

    @media screen and (max-width: 770px) {
      .reportCard {
        .twoSides {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
          min-width: 75% !important;

          .cardContent {
            margin: 1rem;
            padding: 10px 0;
            width: 100% !important;

            .cardDetails {

              p {
                margin-top: 7px;
                gap: 5px !important;
                font-size: 15px;
              }
            }
          }
        }
      }
    }

    @media screen and (max-width: 500px) {
      display: none;
    }

    .reportCard {
      background: white;
      cursor: pointer;
      padding: 1rem;
      min-width: 35%;
      min-height: 200px;
      box-shadow: 1px 1px 5px 3px #0f0f0f33;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-radius: 15px;

      .twoSides {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        min-width: 35%;

        .cardContent {
          display: flex;
          // flex-wrap: wrap;
          width: 45%;
          gap: 0.3rem;
          align-items: flex-start;

          // border: 1px solid red !important;

          .cardDetails {
            margin-left: 5px;

            p {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 3px;

              .skel {

                .ant-skeleton-button {
                  min-width: 80px !important;
                  border-radius: 10% !important;
                }
              }
            }


          }

          .cardLogo {
            margin: 2px;
          }
        }

      }
    }

    .chartsBox,
    .donutBox {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      background-color: white;
      // box-shadow: 5px 5px 5px 3px rgba(0, 0, 0, 0.15);
      box-shadow: 1px 1px 5px 3px #0f0f0f33;

      padding: 1rem;
      border-radius: 0.85rem;
    }

    .charts,
    .donuts {
      height: 300px;
    }
  }

  .endCards {
    height: 30vh;
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap !important;
    width: 100%;

    .progress {
      cursor: pointer;
      background: white;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      font-size: medium;
      min-width: 60%;
      box-shadow: 1px 1px 5px 3px #0f0f0f33;
      border-radius: 15px;
      padding: 2rem;
      position: relative;
      height: 100%;

      .progs {
        height: 70%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .headingProgress {
        top: 1rem;
        left: 1rem;
        display: block;
        position: absolute;
      }
    }

    .card {
      background: white;
      cursor: pointer;
      padding: 1rem;
      min-width: 35%;
      height: 100%;
      box-shadow: 1px 1px 5px 3px #0f0f0f33;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      border-radius: 15px;


      .twoSides {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        min-width: 35%;

        .cardContent {
          display: flex;
          // flex-wrap: wrap;
          width: 45%;
          gap: 0.3rem;
          align-items: flex-start;


          .cardDetails {
            margin-left: 5px;




          }

          .cardLogo {

            margin: 2px;
          }
        }
      }
    }
  }
}